<div>
	<div class="modal-header">
		<h4 class="modal-title modal-title-name" style="width:100%;margin-left: 8px;">{{my_modal_title}}</h4>
		<div>
			<div class="dropdown dropleft export cursor-pointer">
				<svg id="dropdownMenuButton" (mouseover)="onMouseover($event)" (mouseleave)="onMouseout($event)" data-bs-toggle="dropdown" aria-expanded="false"
					xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical three_dots dropdown-toggle" viewBox="0 0 16 16">
					<path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
					</svg>
				<div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
					<!-- <div style="padding:5px;color: #2273BC;" class="glyphicon glyphicon-new-window"></div> -->
					<div class="export-style" (click)="onExportCsv()" > <i class="fa fa-file"></i> Export</div>
				</div>
			</div>
		</div>
		<button type="button" aria-label="Close" class="btn-close" (click)="closeCustom()">
			<!-- <span aria-hidden="true">&times;</span> -->
		</button>
	</div>
	<div class="modal-body mainpanal" style="margin:0 !important;padding-top: 2px;">
		<div id="policyCountSummary" style="display: flex;">
			<div *ngFor="let option of product_count_arr" style="display:flex;margin-left: 10px;font-size: 14px;">
				<div style="color:#2c5e91;font-weight: 700;"> {{option.product}}:&nbsp;</div>
				<div style="font-weight: bold;"> {{option.product_count | number}}  &nbsp; &nbsp;</div><br>
			</div>
		</div>
		<div class="panel-body modal-body table-responsive" id="popupContainer" style="padding: 10px;padding-top:0px;">
			<table #mapPopupTable class="table no-wrap" id="mapPopupTable" style="white-space: nowrap;"></table>
		</div>
		<div *ngIf="popupAgentInformationFlag" id="applicationMainContainer">
			<div class="my-2">
				<button id="popupBackButton" type="button" class="btn btn-primary px-3 py-1 background-primary3" (click)="onBackButtonClick()">Back</button>
				<!-- <button style="margin: 10px 15px 10px 15px;" id="popupBackButton" type="button" class="btn btn-primary green-bgColor white-color" (click)="onAppDetailsExportClick()">Export</button> -->
			</div>
			<div id="appDetailsContainer" class="">
				<div class="row m-0 po_boxbg">
					<div class="col-12 px-0 py-2 hdr">Agent Information</div>
					<div class="col-4 p-0">
						<div class="agent-info">
							<div class="d-flex">
								<h4 class="agent-info-field-name">AgentVizion Id: </h4> <h4>&nbsp; {{popupAgentInformation["CRM Id"]}} </h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Name: </h4> <h4>&nbsp; {{popupAgentInformation.name}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">NPN: </h4> <h4>&nbsp; {{popupAgentInformation.NPN}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Companies: </h4> <h4>&nbsp; {{popupAgentInformation.Carriers}}</h4>
							</div>
						</div>
					</div>
					<div class="col-4 p-0">
						<div class="agent-info">
							<div class="d-flex">
								<h4 class="agent-info-field-name">Agency: </h4> <h4>&nbsp; {{popupAgentInformation.AgencyName}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Contact No: </h4> <h4>&nbsp; {{popupAgentInformation.number}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">State: </h4> <h4>&nbsp; {{popupAgentInformation.state}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Email: </h4> <h4>&nbsp; {{popupAgentInformation.address}}</h4>
							</div>
						</div>
					</div>
					<div class="col-4 p-0">
						<div class="agent-info">
							<div class="d-flex">
								<h4 class="agent-info-field-name">Course Name: </h4> <h4>&nbsp; {{popupAgentInformation.course_name}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">License Number: </h4> <h4>&nbsp; {{popupAgentInformation.license_number}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">License Type: </h4> <h4>&nbsp; {{popupAgentInformation.license_type}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">License Status: </h4> <h4>&nbsp; {{popupAgentInformation.license_status}}</h4>
							</div>
						</div>
					</div>
				</div>
				<div *ngIf="infoAndUnderwritingInformationFlag" class="row m-0 po_boxbg">
					<div class="col-12 px-0 py-2 hdr">Application Information</div>
					<div class="col-4 p-0">
						<div class="agent-info">
							<div class="d-flex">
								<h4 class="agent-info-field-name">App ID: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['App-ID']}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Entry: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Entry']}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">App Status: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['App Status']}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Product: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.Product}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Carrier: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.Carrier}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Face Amount: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Face Amount'] | currency: "USD"}}</h4>
							</div>
						</div>
					</div>
					<div class="col-4 p-0">
						<div class="agent-info">
							<div class="d-flex">
								<h4 class="agent-info-field-name">Rider: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.Rider | currency: "USD"}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Annual Premium: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Annual Premium'] | currency: "USD"}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Mode: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.Mode}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Modal Premium: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Modal Premium'] | currency: "USD"}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Insured Name: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Insured Name']}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Insured Address: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Insured Address']}}</h4>
							</div>
						</div>
					</div>
					<div class="col-4 p-0">
					<div class="agent-info">
						<div class="d-flex">
							<h4 class="agent-info-field-name">City: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.City}}</h4>
						</div>
						<div class="d-flex">
							<h4 class="agent-info-field-name">State: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.State}}</h4>
						</div>
						<div class="d-flex">
							<h4 class="agent-info-field-name">Zip Code: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Zip Code']}}</h4>
						</div>
						<div class="d-flex">
							<h4 class="agent-info-field-name">Page Last Visited: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Page Last Visited']}}</h4>
						</div>
						<div class="d-flex">
							<h4 class="agent-info-field-name">Issue: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Issue']}}</h4>
						</div>
						<div class="d-flex">
							<h4 class="agent-info-field-name">Effective: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Effective']}}</h4>
						</div>
					</div>
					</div>
				</div>
				<div *ngIf="infoAndUnderwritingInformationFlag" class="row m-0 po_boxbg">
					<div class="col-12 px-0 py-2 hdr">Manual Underwriting Information</div>
					<div class="col-4 p-0">
						<div class="agent-info">
							<div class="d-flex">
								<h4 class="agent-info-field-name">Rate/Risk Class: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Rate/Risk Class']}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Case Status: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Case Status']}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Completion Date: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Completion Date']}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Final UW Decision: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Final UW Decision']}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Entry: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Underwriting Entry']}}</h4>
							</div>
						</div>
					</div>
					<div class="col-4 p-0">
						<div class="agent-info">
							<div class="d-flex">
								<h4 class="agent-info-field-name">Declined Reason: </h4> <h4
								[ngStyle]="{'color':getColorClasses(infoAndUnderwritingInformation?.['Declined Reason'])}">&nbsp; {{infoAndUnderwritingInformation?.['Declined Reason']}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name" >Requirements: </h4>
								<h4 title="{{infoAndUnderwritingInformation?.Requirements}}" style="width: 240px;text-overflow: ellipsis;white-space: nowrap;overflow: hidden;text-decoration: none;color: #626B78 !important;">&nbsp; {{infoAndUnderwritingInformation?.Requirements}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Billing Category: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.['Billing Category']}}</h4>
							</div>
							<div class="d-flex">
								<h4 class="agent-info-field-name">Notes: </h4> <h4>&nbsp; {{infoAndUnderwritingInformation?.Notes}}</h4>
							</div>
						</div>
					</div>
					<div class="col-4 p-0">
						<div class="agent-info">
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
