<div class="modal-header">
	<h4 class="modal-title">{{my_modal_title}}</h4>
	<button type="button" class="close" aria-label="Close" (click)="closeCustom()">
		<span aria-hidden="true">&times;</span>
	</button>
</div>
<div class="modal-body pt-0">
		<div *ngIf="my_modal_popupObj && my_modal_popupObj['agentMoreDetails'] === 'no' && agentDetailsObject" class="row po_boxbg margin-0-important">
			<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 agent-detail-border">
				<div class="hdr">{{my_modal_popupObj['crm']}} Details: </div>
				<div class="row" style="margin-top: 5px;">
					<div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 padding-0-important">
						<div class="agent-info">
					<div class="display-flex">
						<h4 class="agent-info-field-name">{{my_modal_popupObj['crm']}}: </h4>
						<h4>&nbsp;{{agentDetailsObject['CRM Id']}}</h4>
					</div>
					<div class="display-flex">
						<h4 class="agent-info-field-name">Name: </h4> <h4>&nbsp;{{agentDetailsObject.name}}</h4>
					</div>
					<div *ngIf="my_modal_popupObj['clientType'] === 'carrier'" class="display-flex">
						<h4 class="agent-info-field-name">Companies: </h4> <h4>&nbsp;{{agentDetailsObject.Carriers}}</h4>
					</div>
					<div *ngIf="my_modal_popupObj['clientType'] === 'agency'" class="display-flex">
						<h4 class="agent-info-field-name">Carriers: </h4> <h4>&nbsp;{{agentDetailsObject.Carriers}}</h4>
					</div>
				</div>
					</div>
					<div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 padding-0-important">
						<div class="agent-info">
							<div class="display-flex">
								<h4 class="agent-info-field-name">Agency: </h4> <h4>&nbsp;{{agentDetailsObject.AgencyName}}</h4>
							</div>
							<div class="display-flex">
								<h4 class="agent-info-field-name">Contact No.: </h4> <h4>&nbsp;{{agentDetailsObject.number}}</h4>
							</div>
							<div class="display-flex">
								<h4 class="agent-info-field-name">Email: </h4> <h4>&nbsp;{{agentDetailsObject.address}}</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div *ngIf="my_modal_popupObj &&  my_modal_popupObj['agentMoreDetails'] === 'yes' && agentDetailsObject" class="row margin-0-important">
			<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 " style="border:1px solid #ddd;">
				<div class="hdr">{{my_modal_popupObj['crm']}} Details: </div>
				<div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 display-flex padding-0-important" >
					<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 padding-0-important">
						<div class="agent-info">
							<div class="display-flex">
								<h4 class="agent-info-field-name">{{my_modal_popupObj['crm']}}: </h4> <h4>&nbsp;{{agentDetailsObject['CRM Id']}}</h4>
							</div>
							<div class="display-flex">
								<h4 class="agent-info-field-name">Name: </h4> <h4>&nbsp;{{agentDetailsObject.name}}</h4>
							</div>
							<div class="display-flex">
								<h4 class="agent-info-field-name">NPN: </h4> <h4>&nbsp;{{agentDetailsObject.NPN}}</h4>
							</div>
							<div class="display-flex" *ngIf="my_modal_popupObj['clientType'] === 'carrier'">
								<h4 class="agent-info-field-name">Companies: </h4> <h4>&nbsp;{{agentDetailsObject.Carriers}}</h4>
							</div>
							<div class="display-flex" *ngIf="my_modal_popupObj['clientType'] === 'agency'">
								<h4 class="agent-info-field-name">Carriers: </h4> <h4>&nbsp;{{agentDetailsObject.Carriers}}</h4>
							</div>
						</div>
					</div>
					<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 padding-0-important">
						 <div class="agent-info">
							<div class="display-flex">
								<h4 class="agent-info-field-name">Agency: </h4> <h4>&nbsp;{{agentDetailsObject.AgencyName}}</h4>
							</div>
							<div class="display-flex">
								<h4 class="agent-info-field-name">Contact No: </h4> <h4>&nbsp;{{agentDetailsObject.number}}</h4>
							</div>
							<div class="display-flex">
								<h4 class="agent-info-field-name">State: </h4> <h4>&nbsp;{{agentDetailsObject.state}}</h4>
							</div>
							<div class="display-flex">
								<h4 class="agent-info-field-name">Email: </h4> <h4>&nbsp;{{agentDetailsObject.address}}</h4>
							</div>
						</div>
					</div>
					<div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 padding-0-important">
						<div class="agent-info">
							<div class="display-flex">
								<h4 class="agent-info-field-name">Course Name: </h4> <h4>&nbsp;{{agentDetailsObject.course_name}}</h4>
							</div>
							<div class="display-flex">
								<h4 class="agent-info-field-name">License Number: </h4> <h4>&nbsp;{{agentDetailsObject.license_number}}</h4>
							</div>
							<div class="display-flex">
								<h4 class="agent-info-field-name">License Type: </h4> <h4>&nbsp;{{agentDetailsObject.license_type}}</h4>
							</div>
							<div class="display-flex">
								<h4 class="agent-info-field-name">License Status: </h4> <h4>&nbsp;{{agentDetailsObject.license_status}}</h4>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	<div>
		<div class="card mt-3 m-5 mainpanal">
			<div class="card-header ">
			</div>
			<div class="card-body">
			<!--  -->{{my_modal_popupObj['chartInfo']}}
				<!-- <app-chart [chartInfo] = "chartInfo">chart</app-chart> -->
			</div>
		</div>
	</div>
	<div>
	</div>
</div>
