import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FlashMessagesService } from 'angular2-flash-messages';
import { AuthService} from '../auth.service';
import $ from 'jquery';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import Validations from './../../shared/utils/validations';
import { ThisReceiver } from '@angular/compiler';
import { LocalstorageService } from 'src/app/shared/services/localstorage.service';

export class User {
    private email: string;
    private npn: number;
    private otp: number
    private oldpassword: String;
    private password: string;
    private confirmPassword: string;
  }
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})

export class ChangePasswordComponent implements OnInit, AfterViewChecked {
    userInfo: object;
    cpform: FormGroup;
    submitted = false;
    toggleUser: string = 'yes';
    toggleUserFlag: Boolean = false;
    redColor: string = '#ff0000';
    npnFlag: boolean = false;
    userEmail: string = '';
    userNpn: string = '';
    routeParams: object = {
        url: '',
        queryParam: ''
    }
    logo: string;
    app_version: number;
    emailId: string | number | string[];
    email1: string = '';
    npn: string | number | string[];
    password1: string = '';
    cpassword2: string = '';
    oldpassword: string = '';
    newpassword: string = '';
    confirmPassword: string = '';
    user: any = {
        email: '',
        npn: '',
        password: '',
        oldpassword: '',
        confirmPassword: '',
        otp: ''
    };
    flashMessageDefaultTime = 2500;

    constructor(
        private router: Router,
            private flashMessagesService: FlashMessagesService,
            private authService: AuthService,
            private formBuilder: FormBuilder,
            private localStorageService: LocalstorageService,
    ) { }

    ngAfterViewChecked(): void {
        this.cpform.patchValue({'email': this.userInfo['email']});
    }

    ngOnInit(){
        $('#changePwOtpContainer').hide();
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.user['email'] = this.userInfo['email'];


        this.cpform = this.formBuilder.group(
            {
                email: [this.user['email'], [Validators.required, Validators.email]],
                otp: ['', [ Validators.required, Validators.minLength(6), Validators.pattern("^[0-9]*$")]],
                number: ['', [Validators.required, Validators.minLength(10), Validators.pattern("^[0-9]*$")]],
                password1: ['', [Validators.required]],
                password: ['', [Validators.required,Validators.minLength(8),
                    Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
                confirmPassword: ['', Validators.required],
                acceptTerms: [false, Validators.requiredTrue]
            },
            {
                validators: [Validations.match('password', 'confirmPassword')]
            }
        );

        this.getClientConfig();
    }

    get f(): { [key: string]: AbstractControl } {
        return this.cpform.controls;
    }

    onSubmit(): void {
        this.submitted = true;

        if (this.cpform.get('email').valid &&
            this.cpform.get('password').valid &&
            this.cpform.get('confirmPassword').valid &&
            this.cpform.get('password1').valid) {
            this.changePassword();
        } else {
            return;
        }
        // console.log(JSON.stringify(this.cpform.value, null, 2));
    }

    onReset(): void {
        this.submitted = false;
        this.cpform.reset();
    }

    checkNpnOtp = function(user: any) {
        user.password = '';
        var userNpn = user.npn;
        if (userNpn) {
            this.authService.checkNpnOtp({ usernpn: userNpn }).subscribe(obj => {
                var res = obj.msg;
                this.isAgree = obj.isEulaApplicable ? false : true;
                // this.isEulaAccepted = obj.isEulaApplicable;
                if (res === this.invalidLoginAttemptMsg) {
                    this.flashMessagesFunction(res, 'danger');
                    // $('#signInBtn').button('loading');
                    $('#siginLinksContainer, #correctOtpContainer, #otpContainer').hide();
                    setTimeout(function() {
                        // $('#signInBtn').button('reset');
                        $('#siginLinksContainer').removeClass('links-inactive');
                        $('#toggleUserContainer, #userNameContainer, #npnContainer, #passwordContainer, #siginLinksContainer, #correctOtpContainer, #signBtn').hide();
                        $('#otpContainer').show();
                        this.resendOtp(user);
                    }, 3000);
                } else if (res === this.lockedMessage) {
                    $('#siginLinksContainer').addClass('links-inactive');
                    this.flashMessagesFunction(res, 'danger');
                } else if (res === 'User unblocked') {
                    $('#siginLinksContainer').removeClass('links-inactive');
                    //this.flashMessagesFunction('', 'danger');
                } else if (res === this.accountVerificationMsg) {
                        $("ul .active").removeClass("active");
                        $("ul li:first").addClass("active");
                        this.emailOrNPNTab();
                        $('#toggleUser li:last-child').remove();
                        this.flashMessagesFunction(res, 'danger');
                } else {
                    if (res !== '' || res.length === 0) {
                        this.flashMessagesFunction(res, 'danger');
                        $('#siginLinksContainer').addClass('links-inactive');
                    } else {
                        this.flashMessagesFunction(res, 'success');
                    }
                    $('#siginLinksContainer').removeClass('links-inactive');
                }
            });
        } else {
            this.flashMessagesFunction(this.enterNPNMsg, 'danger');
        }
    };


    flashMessagesFunction(msg: any, alert: any, timeout:any){
        //this.flashMessagesService.show(msg, { cssClass: 'alert-danger', timeout: 1000000, closeOnClick:true, showCloseBtn: true });
        if(msg !== ""){
            if(alert === 'danger'){
                this.flashMessagesService.show(msg, { cssClass: 'alert-danger', timeout: timeout, closeOnClick:true, showCloseBtn: true });
            } else {
                this.flashMessagesService.show(msg, { cssClass: 'alert-success', timeout: timeout, closeOnClick:true, showCloseBtn: true});
            }
        }
    }

    redirectToLoginPage() {
        const routerState = this.router.routerState.snapshot;
        this.router.navigate([this.routeParams['url']], { queryParams: { } });
    }

    //route to login page
    goTologinPage = function() {
        $('#changePwOtpContainer').hide();
        this.routeParams = {
            url: "/app/agency",
            queryParam: ''
        }
        this.redirectToLoginPage()
    };

    getClientConfig(){
        this.authService.getClientConfig().subscribe(response => {
            this.logo = response[0].logo;
            this.app_version = response[0].app_version;
            this.toggleUser = response[0].toggle_user;
            if (this.toggleUser === 'yes') {
                this.toggleUserFlag = true;
                $('#toggleUserContainer').show();
                $('#userNameContainer, #npnContainer, #forgetNpnContainer').hide();
                $('#emailUserNameContainer').show();
            } else {
                this.toggleUserFlag = false;
                $('#npnContainer').hide();
                $('#toggleUserContainer, #forgetNpnContainer, #otpContainer').hide();
                $('#userNameContainer').show();
            }
        });
    }

    emailOrNPNTab(val) {
        if (val === 'npn') {
            this.npnFlag = true;
            $('#emailContainer').hide();
            $('#npnContainer').show();
        } else {
            this.npnFlag = false;
            $('#npnContainer').hide();
            $('#emailContainer').show();
        }
    }

    validatePassword = (password) =>{
        const re = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}/;
        return re.test(password);
    }

    //adding otp container
    changePassword() {
        this.emailId = this.cpform.get('email')?.value;
        this.npn = this.cpform.get('number')?.value;
        this.oldpassword = this.cpform.get('password1')?.value;
        this.newpassword = this.cpform.get('password')?.value;
        let checkNewPwdValidator = this.validatePassword(this.newpassword);
        this.confirmPassword = this.cpform.get('confirmPassword')?.value;
        if (this.npnFlag) {
            this.emailId = this.npn;
        }
        if (this.newpassword.length && this.confirmPassword.length && this.oldpassword.length) {
            if (this.oldpassword !== this.newpassword) {
                if(checkNewPwdValidator){
                    if (this.newpassword === this.confirmPassword) {
                        if (this.toggleUser === 'yes') {
                            var paramObj = { 'param1': this.emailId, 'param2': this.oldpassword, 'param3': this.newpassword, 'npnFlag': this.npnFlag };
                            this.authService.validateChangepasswordUser(paramObj).subscribe(response => {
                                if (response['message'] === 'success') {
                                    $('#toggleUser, #npnContainer, #emailContainer, #oldPasswordContainer, #newPasswordContainer, #confirmPwContainer, #flashContainer, #cancelAndSubmitContainer').hide();
                                    // var element1 = $('<div class="form-group" id="changeOtpContainer">' +
                                    //     '<label for="otp">OTP</label><a id="confirmResendOtp" style="float: right;" class="cursor-pointer" ng-click="resendOtp(user)">Resend OTP</a>' +
                                    //     '<input type="text" name="otp" class="form-control" id="otp" placeholder="Enter OTP" required="" ng-model="user.otp" maxlength="6">' +
                                    //     '</div>' +
                                    //     '<div style="text-align: center;margin-bottom: 10px;font-weight: 700;">' +
                                    //     '<span id="flashMsgId" ></span>' +
                                    //     '</div>' +
                                    //     '<div class="form-actions">' +
                                    //     '<button id="confirmOtpBtn" type="submit" class="btn-custom btn" (keyup.enter)="confirmpassword()" ng-click="confirmpassword()" data-loading-text="<i style=\'font-size: 20px;\' class=\'fa fa-spinner fa-spin\'></i> Submit">Change Password</button>' +
                                    //     '<button id="confirmOtpCancelBtn" type="submit" class="btn-custom btn" style="margin-left: 7px;" ng-click="goTologinPage()" (keyup-enter)="goTologinPage()" >Cancel</button></div>');
                                    // var ele = $compile(element1)($scope);
                                    // $('#changePwOtpContainer').append(ele);
                                    this.userEmail = response['email'];
                                    this.userNpn = response['npn'];
                                    $('#changePwOtpContainer').show();
                                    $('#cpCancelAndSubmitContainer, #emailContainer, #npnContainer, #password1, #password, #confirmPassword').hide();
                                    this.authService.sendOtp({ username: response['email'] }).subscribe(res => {
                                        this.flashMessagesFunction(res['msg'], 'success', this.flashMessageDefaultTime);
                                    });
                                } else {
                                    this.flashMessagesFunction(response['message'], 'danger', this.flashMessageDefaultTime);
                                }
                            });
                        } else {
                            var paramObj = { 'param1': this.emailId, 'param2': this.oldpassword, 'param3': this.newpassword, 'npnFlag': false };
                            this.authService.validateChangepasswordUser(paramObj).subscribe(response => {
                                if (response['message'] === 'success') {
                                    $('#toggleUser, #emailContainer, #oldPasswordContainer, #newPasswordContainer, #confirmPwContainer, #flashContainer, #cancelAndSubmitContainer').hide();
                                    // var element1 = $('<div class="form-group" id="changeOtpContainer">' +
                                    //     '<label for="otp">OTP</label><a id="confirmResendOtp" style="float: right;" class="cursor-pointer" ng-click="resendOtp(user)">Resend OTP</a>' +
                                    //     '<input type="text" name="otp" class="form-control" id="otp" placeholder="Enter OTP" required="" ng-model="user.otp" maxlength="6">' +
                                    //     '</div>' +
                                    //     '<div style="text-align: center;margin-bottom: 10px;font-weight: 700;">' +
                                    //     '<span id="flashMsgId" ></span>' +
                                    //     '</div>' +
                                    //     '<div class="form-actions">' +
                                    //     '<button id="confirmOtpBtn" type="submit" class="btn-custom btn" (keyup.enter)="confirmpassword()" ng-click="confirmpassword()" data-loading-text="<i style=\'fot-size: 20px;\' class=\'fa fa-spinner fa-spin\'></i> Submit">Change Password</button>' +
                                    //     '<button id="confirmOtpCancelBtn" type="submit" class="btn-custom btn" style="margin-left: 7px;" ng-click="goTologinPage()" (keyup.enter)="goTologinPage()" >Cancel</button></div>');
                                    // var ele = $compile(element1)($scope);
                                    // $('#changePwOtpContainer').append(ele);
                                    this.userEmail = response['email'];
                                    this.userNpn = response['npn'];
                                    $('#changePwOtpContainer').show();
                                    $('#cpCancelAndSubmitContainer, #emailContainer, #npnContainer, #password1, #password, #confirmPassword').hide();
                                    this.authService.sendOtp({ username: response['email'] }).subscribe(res => {
                                        this.flashMessagesFunction(res['msg'], 'success', this.flashMessageDefaultTime);
                                    });
                                } else {
                                    this.flashMessagesFunction(response['message'], 'danger', this.flashMessageDefaultTime);
                                }
                            });
                        }
                    } else {
                        var msg = 'New Password does not match';
                        this.flashMessagesFunction(msg, 'danger', this.flashMessageDefaultTime);
                    }
                } else {
                    var msg = 'Password must be 8-15 characters containing 1 capital, 1 lower and 1 special character (!,@,#,,%,&,*)';
                    this.flashMessagesFunction(msg, 'danger', this.flashMessageDefaultTime);
                }
            } else {
                var message = 'Your New Password cannot be the same as your Current Password.';
                this.flashMessagesFunction(message, 'danger', this.flashMessageDefaultTime);
            }
        }
    };

    //otp submit functionality
    // $('#changePwOtpContainer').on('click', '#confirmOtpBtn', function() {
    //     var emailId = this.emailId;
    //     var npn = this.npn;
    //     var oldpassword = this.oldpassword;
    //     var newpassword = this.newpassword;
    //     var otp = $('#otp').val();
    //     if (otp.length) {
    //         if (this.npnFlag) {
    //             emailId = npn;
    //         }
    //         SecurityService.changepassword({ 'param1': emailId, 'param2': oldpassword, 'param3': newpassword, 'param4': otp, 'npnFlag': this.npnFlag }, function(response) {
    //             if (response === 'Otp not found') {
    //                 $('#flashMsgId').text('Please enter valid OTP');
    //                 $('#flashMsgId').css({ 'color': 'red' });
    //             } else if (response === 'Password not found') {
    //                 $('#flashMsgId').text('Password not found');
    //                 $('#flashMsgId').css({ 'color': 'red' });
    //             } else {
    //                 delete_cookie('agentvizion-' + emailId.toLowerCase(), emailId, this.npnFlag);
    //                 $('#flashMsgId').text('Password successfully reset. Please log in using new password.');
    //                 $('#flashMsgId').css({ 'color': 'green' });
    //                 $('#changePwOtpContainer').addClass('btns-inactive');
    //                 angular.element('#confirmOtpBtn').button('loading');
    //                 setTimeout(function() {
    //                     this.router.navigate(['/auth/login'], { queryParams: { } });
    //                     angular.element('#confirmOtpBtn').button('reset');
    //                     $('#changePwOtpContainer').removeClass('btns-inactive');
    //                 }, 5000);
    //             }
    //         });
    //     } else {
    //         $('#flashMsgId').text('Please enter valid OTP');
    //         $('#flashMsgId').css({ 'color': 'red' });
    //     }
    // });

    confirmpassword = function () {
        this.emailId = this.cpform.get('email')?.value;
        this.oldpassword = this.cpform.get('password1')?.value;
        this.newpassword = this.cpform.get('password')?.value;
        this.npn = this.cpform.get('number')?.value;
        var otp:any = $('#otp').val();
        if (otp.length) {
            if (this.npnFlag) {
                this.emailId = this.npn;
            }
            this.authService.changepassword({ 'param1': this.emailId, 'param2': this.oldpassword, 'param3': this.newpassword, 'param4': otp, 'npnFlag': this.npnFlag }).subscribe(response => {
                if (response === 'Password successfully reset. Please log in using new password.') {
                    // this.delete_cookie('agentvizion-' + this.userEmail.toLowerCase(), this.emailId);
                    $('#changePwOtpContainer').addClass('btns-inactive');
                    this.flashMessagesFunction(response, 'success',this.flashMessageDefaultTime);
                    //$('#confirmOtpBtn').button('loading');
                    $('#changePwOtpContainer').hide();
                    $('#cpCancelAndSubmitContainer, #emailContainer, #npnContainer, #password1, #password, #confirmPassword').hide();
                    setTimeout(() => {
                        this.localStorageService.remove('_rt');
                        this.localStorageService.remove("_t");
                        this.localStorageService.remove("userTabInfo");
                        this.localStorageService.remove("userInfo");
                        this.localStorageService.remove("activeTabId");
                        this.localStorageService.remove("activeSubTab");
                        this.router.navigate(['/login'], { queryParams: { } });
                        // $('#confirmOtpBtn').button('reset');
                        $('#changePwOtpContainer').removeClass('btns-inactive');
                    }, 5000);
                } else if (response === 'Your Account is now locked. Please contact your Helpdesk or wait 15 minutes to try again.'){
                    this.flashMessagesFunction(response, 'danger',this.flashMessageDefaultTime);
                    $('#confirmOtpBtn').addClass("disabled links-inactive");
                } else {
                    if(response === 'Incorrect OTP - 1 attempts left'){
                        setTimeout(() => {
                            this.localStorageService.remove('_rt');
                            this.localStorageService.remove("_t");
                            this.localStorageService.remove("userTabInfo");
                            this.localStorageService.remove("userInfo");
                            this.localStorageService.remove("activeTabId");
                            this.localStorageService.remove("activeSubTab");
                            this.router.navigate(['/login'], { queryParams: { } });
                            $('#changePwOtpContainer').removeClass('btns-inactive');
                        }, 5000);
                    }
                    this.flashMessagesFunction(response, 'danger', this.flashMessageDefaultTime);
                }
            });
        } else {
            this.flashMessagesFunction('Please enter valid OTP', 'danger', this.flashMessageDefaultTime);
        }
    };

    delete_cookie(name, email) {
        document.cookie = 'agentvizion-' + this.userNpn + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;secure;';
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;secure;';
        this.authService.setCookieOtp({ 'userName': email, 'otp': '', 'npnFlag': this.npnFlag }).subscribe(response => {
        });
    }

    //cancel button, Return to login page
    // $('#changePwOtpContainer').on('click', '#confirmOtpCancelBtn', function () {
    //     location.href = '/#/login';
    // });

    //resend otp functionality
    resendOtp(user){
        // console.log('resendOtp', user, user['email']);
        this.authService.sendOtp({ username: user['email'] }).subscribe(res => {
            this.flashMessagesFunction(res['msg'], 'success', this.flashMessageDefaultTime)
        });
    }
    // $('#changePwOtpContainer').on('click', '#confirmResendOtp', function () {
    //     var emailId = $('#email1').val();
    //     SecurityService.sendOtp({ username: emailId }, function (res) {
    //         $('#flashMsgId').css({ 'color': 'green' });
    //         $('#flashMsgId').text(res);
    //     });
    // });

    //Only allow digits in otp input box
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false;
        }
        return true;
    }

    showPassword(iconId:string, inputId:string) {
        let input = document.getElementById(inputId);
        let icon = document.getElementById(iconId);
        if(input['type'] == 'password'){
            input['type'] = 'text';
            icon.classList.remove('fa-eye');
            icon.classList.add('fa-eye-slash');
        } else {
            input['type'] = 'password';
            icon.classList.remove('fa-eye-slash');
            icon.classList.add('fa-eye');
        }
    }

}
