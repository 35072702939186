<div class="modal-header">
    <h4 class="modal-title">{{performanceModalObj['agentORAgentName']}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body pt-0">
    <div>
        <div *ngIf="performanceModalObj['agentMoreDetails'] === 'no'" class="row po_boxbg  margin-0-important">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 agent-detail-border">
                <div class="hdr">{{performanceModalObj['crm']}} Details: </div>
                <div class="row " style="margin-top: 5px;">
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 padding-0-important">
                        <div class="agent-info">
                            <div class="display-flex">
                                <h4 class="agent-info-field-name">{{performanceModalObj['crm']}}: </h4>
                                <h4>&nbsp;{{agentDetailsObject['CRM Id']}}</h4>
                            </div>
                            <div class="display-flex">
                                <h4 class="agent-info-field-name">Name: </h4> <h4>&nbsp;{{agentDetailsObject['name']}}</h4>
                            </div>
                            <div *ngIf="performanceModalObj['clientType'] === 'carrier'" class="display-flex">
                                <h4 class="agent-info-field-name">Companies: </h4> <h4>&nbsp;{{agentDetailsObject['Carriers']}}</h4>
                            </div>
                            <div *ngIf="performanceModalObj['clientType'] === 'agency'" class="display-flex">
                                <h4 class="agent-info-field-name">Carriers: </h4> <h4>&nbsp;{{agentDetailsObject["Carriers"]}}</h4>
                            </div>
                        </div>
                    </div>
                    <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 padding-0-important">
                        <div class="agent-info">
                            <div class="display-flex">
                                <h4 class="agent-info-field-name">Agency: </h4> <h4>&nbsp; {{agentDetailsObject['AgencyName']}}</h4>
                            </div>
                            <div class="display-flex">
                                <h4 class="agent-info-field-name">Contact No.: </h4> <h4>&nbsp;{{agentDetailsObject['number']}}</h4>
                            </div>
                            <div class="display-flex">
                                <h4 class="agent-info-field-name">Email: </h4> <h4>&nbsp;{{agentDetailsObject['address']}}</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div *ngIf="performanceModalObj['agentMoreDetails'] === 'yes'" class="row margin-0-important">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 " style="border:1px solid #ddd;">
                <div class="hdr">{{performanceModalObj['crm']}} Details: </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 display-flex padding-0-important" >
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 padding-0-important">
                        <div class="agent-info">
                            <h4>{{performanceModalObj['crm']}}: {{performanceModalObj['CRM Id']}}</h4>
                            <h4>Name: {{performanceModalObj['name']}}</h4>
                            <h4>NPN: {{performanceModalObj['NPN']}}</h4>
                            <h4 *ngIf="performanceModalObj['clientType'] === 'carrier'">Companies: {{performanceModalObj['Carriers']}}</h4>
                            <h4 *ngIf="performanceModalObj['clientType'] === 'agency'">Carriers: {{performanceModalObj['Carriers']}}</h4>
                        </div>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 padding-0-important">
                        <div class="agent-info">
                            <h4>Agency: {{performanceModalObj['AgencyName']}}</h4>
                            <h4>Contact No: {{performanceModalObj['number']}}</h4>
                            <h4>State: {{performanceModalObj['state']}}</h4>
                            <h4>Email: {{performanceModalObj['address']}}</h4>
                        </div>
                    </div>
                    <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 padding-0-important">
                        <div class="agent-info">
                            <h4>Course Name: {{performanceModalObj['course_name']}}</h4>
                            <h4>License Number: {{performanceModalObj['license_number']}}</h4>
                            <h4>License Type: {{performanceModalObj['license_type']}}</h4>
                            <h4>License Status: {{performanceModalObj['license_status']}}</h4>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

        <div class="card mt-3 m-5 mainpanal d-none">
            <div class="card-header "></div>
            <div class="card-body">
                <!-- <app-chart [chartInfo] = "chartInfo"></app-chart> -->
            </div>
        </div>
    </div>
    <div>
    </div>
</div>

