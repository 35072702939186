<div class="modal fade modal-box" id="cardDetailsModal" data-bs-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-xl">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title text-center">Update Credit Card Details</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body position-relative">
                <form #cardForm="ngForm" name="cForm" (ngSubmit)="submitCardForm(cardForm)" novalidate>
                    <div class="row g-3">
                        <div class="col-md-12 input-group">
                            <!-- <label for="card_number" class="form-label">Card Number</label> -->
                            <input type="text" class="form-control" placeholder="Card Number" name="card_number" required [(ngModel)]="cardData.card_number" #card_number="ngModel" minlength="16" maxlength="19" (keydown)="creditCardNumberSpacing(cardData.card_number)"
                            [ngClass]="{ 'is-invalid': cardForm.submitted && card_number.errors }" numbersOnly>
                            <span class="input-group-text" *ngIf="isVisa" style="color: #0a1b72;"><i class="fa fa-cc-visa"></i></span>
                            <span class="input-group-text" *ngIf="isMasterCard" style="color: #0a1b72;"><i class="fa fa-cc-mastercard"></i></span>
                                <div *ngIf="cardForm.submitted && card_number.errors" class="invalid-feedback">
                                    <div *ngIf="card_number.errors['required']">Card Number is required</div>
                                    <div *ngIf="card_number.errors['minlength']">
                                        Card Number must be 16 digits
                                    </div>
                                    <div *ngIf="card_number.errors['maxlength']">
                                        Card Number must be 16 digits
                                    </div>
                                </div>
                        </div>
                        <div class="col-md-6">
                            <!-- <label for="exp_month" class="form-label">Card Expiry Month</label> -->
                            <input type="text" class="form-control" placeholder="Month" name="exp_month" required [(ngModel)]="cardData.exp_month" #exp_month="ngModel" minlength="1" maxlength="2"
                            [ngClass]="{ 'is-invalid': cardForm.submitted && exp_month.errors }" numbersOnly>
                                <div *ngIf="cardForm.submitted && exp_month.errors" class="invalid-feedback">
                                    <div *ngIf="exp_month.errors['required']">Card Expiry Month is required</div>
                                    <div *ngIf="exp_month.errors['minlength']">
                                        Card Number must be 3 digits
                                    </div>
                                    <div *ngIf="exp_month.errors['maxlength']">
                                        Card Number must be 3 digits
                                    </div>
                                </div>
                        </div>
                        <div class="col-md-6">
                            <!-- <label for="exp_year" class="form-label">Card Expiry Year</label> -->
                            <input type="text" class="form-control" placeholder="Year" name="exp_year" required [(ngModel)]="cardData.exp_year" #exp_year="ngModel" maxlength="4" minlength="4"
                            [ngClass]="{ 'is-invalid': cardForm.submitted && exp_year.errors }" numbersOnly>
                            <div *ngIf="cardForm.submitted && exp_year.errors" class="invalid-feedback">
                                <div *ngIf="exp_year.errors['required']">Card Expiry Year is required</div>
                                <div *ngIf="exp_year.errors['minlength']">Card Expiry must be 4 digit</div>
                                <div *ngIf="exp_year.errors['maxlength']">Card Expiry must be 4 digit</div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <!-- <label for="card_cvv" class="form-label">Card CVV</label> -->
                            <input type="password" class="form-control" placeholder="CVV" name="card_cvv" required [(ngModel)]="cardData.card_cvv" #card_cvv="ngModel" minlength="3" maxlength="4"
                            [ngClass]="{ 'is-invalid': cardForm.submitted && card_cvv.errors }" numbersOnly>
                                <div *ngIf="cardForm.submitted && card_cvv.errors" class="invalid-feedback">
                                    <div *ngIf="card_cvv.errors['required']">Card CVV is required</div>
                                    <div *ngIf="card_number.errors['minlength']">
                                        Card Number must be 3 digits
                                    </div>
                                    <div *ngIf="card_number.errors['maxlength']">
                                        Card Number must be 3 digits
                                    </div>
                                </div>
                        </div>
                        <div class="col-md-6">
                            <label for="card_cvv" class="form-label">3 or 4 digits usually found on the signature strip</label>
                        </div>
                        
                        <div class="col-12 text-center">
                            <button type="submit" [disabled]="cardForm.invalid" class="btn btn-success btn-lg">Proceed</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>

<!-- Modal -->
<div class="modal fade modal-box" id="regDetailsModal" data-bs-backdrop="static" aria-hidden="true">
    <div class="modal-dialog modal-xl" style="width: 40%;">
        <div class="modal-content">
            <div class="modal-header border-bottom-0">
                <h5 class="modal-title text-uppercase" id="exampleModalLabel">{{userInfo['name']}}</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body text-start text-black p-4">
                
                <!-- <h4 class="mb-5" style="color: #35558a;">Thanks for your subscription</h4> -->
                <h6 class="mb-0" style="color: #35558a;">Registration Details</h6>
                <hr class="mt-2 mb-4"
                    style="height: 0; background-color: transparent; opacity: .75; border-top: 2px dashed #9e9e9e;">

                <div class="d-flex justify-content-between">
                    <p class="fw-bold mb-0">Invoice Number</p>
                    <p class="text-muted mb-0">{{registrationDetails?.latest_invoice}}</p>
                </div>
                <div class="d-flex justify-content-between">
                    <p class="fw-bold mb-0">Registration Plan</p>
                    <p class="text-muted mb-0">{{registrationDetails?.plan?.id | uppercase}}</p>
                </div>
                <div class="d-flex justify-content-between">
                    <p class="fw-bold mb-0">Registration Interval</p>
                    <p class="text-muted mb-0">{{registrationDetails?.plan?.interval | uppercase}}</p>
                </div>
                <div class="d-flex justify-content-between">
                    <p class="fw-bold mb-0">Registration Amount</p>
                    <p class="text-muted mb-0">{{registrationDetails?.plan?.amount/100 | currency}}</p>
                </div>
                <div class="d-flex justify-content-between">
                    <p class="fw-bold mb-0">Registration Date</p>
                    <p class="text-muted mb-0">{{registrationDetails?.created * 1000 | date}}</p>
                </div>

                <div class="d-flex justify-content-between">
                    <p class="fw-bold mb-0">Registration Validity</p>
                    <p class="text-muted mb-0">{{registrationDetails?.current_period_end * 1000 | date}}</p>
                </div>
            </div>
        </div>
    </div>
</div>