<div class="modal-header">
  <h4 class="modal-title w-100">{{ title }}</h4>
  <div>
    <div class="dropdown dropleft export cursor-pointer">
      <svg id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false"
        xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical three_dots dropdown-toggle" viewBox="0 0 16 16">
        <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
        </svg>
      <div class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton">
        <!-- <div style="padding:5px;color: #2273BC;" class="glyphicon glyphicon-new-window"></div> -->
        <div class="export-style" (click)="onExportCsv()" > <i class="fa fa-file"></i> Export</div>
      </div>
    </div>
  </div>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
  </button>
</div>
<div class="modal-body mainpanal" style="margin: 0 !important">
  <div *ngIf="weekNo!='N/A' && !weekData">Loading...</div>
  <div *ngIf="weekNo!='N/A' && weekData && weekData.length==0">No data available</div>
  <div *ngIf="weekNo=='N/A'">Not applied</div>
  <div *ngIf="weekData && weekData.length" class="panel-body modal-body table-responsive" style="padding: 10px">
    <div>
      <table class="table table-responsive custom-data-table">
        <thead class="av-table-header">
        <tr class="header-row">
          <th *ngFor="let header of tableHeader">
            <div [ngClass]="currencyColumns.includes(header)?'text-end' :''">{{header}}</div>
          </th>
        </tr>
      </thead>
      <tbody class="custom-data-table-body">
        <tr *ngFor="let rowData of weekData">
          <td *ngFor="let header of tableHeader">
            <div [ngClass]="currencyColumns.includes(header)?'text-end' :''">
              {{currencyColumns.includes(header)?formateCurrency(rowData[header]) :rowData[header]}}
            </div>
          </td>
        </tr>
      </tbody>
      </table>
    </div>
    <!-- <div *ngFor="let item of weekData | keyvalue">
    {{item.key}}: {{item.value | json}}
  </div> -->
  </div>
  <div class="modal-footer"></div>
</div>
