import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { DashboardConfigService } from './shared/services/dashboard-config.service';
import { ButtonClickService, CallerDetails } from './button-click-service.service';
declare var require: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  title = 'dyncss';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private dashboardConfigService: DashboardConfigService,
    private buttonClickService: ButtonClickService
  ) { 
    // Subscribe to button click event
    // this.buttonClickService.onButtonClick().subscribe((callerDetails: CallerDetails) => {
    //   console.log('Button clicked from component:', callerDetails.component);
    //   console.log('Function name:', callerDetails.functionName);
    //   // ... Handle button click event with caller details ...
    // });
  }

  async ngOnInit(): Promise<void> {
    await this.dashboardConfigService.getDashboardConfig();
  }

  loadStyle(styleName: string) {
    const head = this.document.getElementsByTagName('head')[0];

    let themeLink = this.document.getElementById(
      'client-theme'
    ) as HTMLLinkElement;
    if (themeLink) {
      themeLink.href = styleName;
    } else {
      const style = this.document.createElement('link');
      style.id = 'client-theme';
      style.rel = 'stylesheet';
      style.href = `../scss/custom/theme1/new_style.css`;

      head.appendChild(style);
    }
  }
}
