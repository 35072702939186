import { Component, OnInit, Output, Input, EventEmitter, AfterViewInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from 'src/app/shared/services/common.service';
import { CommonConstant } from './../../shared/constants/common-constant';
import { configJson } from '../../shared/services/dashboard-config.service';
import { filter } from 'rxjs/operators';

export class TableConfig {
  tableId: string = '';
  apiName: string = '';
  exportName: string = '';
  columnDefs: Array<object> = [];
  scrollY: string = '';
  paging: string = '';
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [CommonConstant]
})
export class HomeComponent implements OnInit, AfterViewInit {
  isSelectorLoaded: any = false;
  SSOLogin: any;
  isHeaderLoaded: boolean = false;
  activeRoute = [];

  constructor(
    private commonService: CommonService,
    private router: Router,
    private spinner: NgxSpinnerService
  ) { 
    
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      // this.currentRoute = event.url;          
      console.log(event);
      this.activeRoute = event.url.split('/');
    });
  }

  ngOnInit(): void {
    // setInterval(()=>)
  }

  ngAfterViewInit(): void {
    this.commonService.checkSelectorLoaded.subscribe(res => {
      this.isSelectorLoaded = res;
    })
  }

  ngOnDestroy() {
    if (this.commonService.checkSelectorLoaded) {
      this.isSelectorLoaded = false;
    }
  }
}
