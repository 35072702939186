import { Injectable } from '@angular/core';
import { configJson } from '../../../shared/services/dashboard-config.service';
import { LocalstorageService } from '../../services/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class TableService {
  userInfo: any;
  userRole: string = '';
  clientType: string = '';
  companyFilter: string = '';
  constructor(
    private localStorageService: LocalstorageService,
  ) {
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
    this.userRole = this.userInfo['role'];
    this.companyFilter = this.userInfo['companyFilter'];
    this.clientType = configJson.clientType;
  }

  tableConfigObj: object = {
    'title': '',
    'serverSideProcessing': true,
    'responsive': false,
    'width': '100%',
    'apiName': '',
    'id': '',
    'tabId': '',
    'fileNameToExport': '',
    'scrollY': '',
    'headers': 'disabled',
    'paging': '',
    'thresholds': [],
    'columnFilters': [],
    'clickEventOn': configJson.tableClickEvents.tableClickEvents,
    'columnDefs': []
  }

  setTableConfigObj(configObj) {
    if (configObj) {
      Object.entries(configObj).forEach(([key, value]) => {
        this.tableConfigObj[key] = value;
      });
    }
  }

  getTableConfigObj() {
    return this.tableConfigObj;
  }

  getTableClickEvents() {
    return configJson.tableClickEvents['tableClickEvents'];
  }

  getTableYearlyReportSaleClickEvents() {
    return configJson.tableClickEvents['yearlyReportSaleClickEvents'];
  }

  //Returns the table data config based on the api name passed
  getTableDataConfig(api) {
    let tableDataConfig = [];
    let tableColumnDef = [];
    let fileExportName: any = '';
    switch (api) {
      case 'getAgentDetails':
        // tableDataConfig = configJson.tableColumnConfig['tableDataConfigAgentDetails'];
        if (this.clientType === 'carrier') {
          if (this.userRole !== 'Agent' && this.userRole !== 'Manager') {
            tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
          } else {
            tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
          }
        } else {
          if (this.userRole !== 'Agent' && this.userRole !== 'Manager') {
            tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
          } else {
            tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
          }
        }
        fileExportName = 'Agent Details';
        break;
      case 'getAgentDetailsTap':
        // tableDataConfig = configJson.tableColumnConfig['tableDataConfigAgentDetailsTap'];
        if (this.userRole !== 'Agent' && this.userRole !== 'Manager') {
          tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
        } else {
          tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
        }
        fileExportName = 'Agent Details';
        break;
      case 'getCommissionData':
        // tableDataConfig = configJson.tableColumnConfig['tableDataConfigCommission'];
        tableColumnDef = [{ "visible": false, "targets": [1] }];
        fileExportName = 'Commission Data';
        break;
      case 'getHighPerformers':
        // tableDataConfig = configJson.tableColumnConfig['tableDataConfigHighPerformers'];
        if (this.userRole !== 'Agent' && this.userRole !== 'Manager') {
          tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
        } else {
          tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
        }
        fileExportName = 'High Performers';
        break;
      case 'getHighPerformersTap':
        // tableDataConfig = configJson.tableColumnConfig['tableDataConfigHighPerformersTap'];
        if (this.userRole !== 'Agent' && this.userRole !== 'Manager') {
          tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
        } else {
          tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
        }
        fileExportName = 'High Performers';
        break;
      case 'getLowPerformers':
        // tableDataConfig = configJson.tableColumnConfig['tableDataConfigLowPerformers'];
        if (this.userRole !== 'Agent' && this.userRole !== 'Manager') {
          tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
        } else {
          tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
        }
        fileExportName = 'Low Performers';
        break;
      case 'getLowPerformersTap':
        // tableDataConfig = configJson.tableColumnConfig['tableDataConfigLowPerformersTap'];
        if (this.userRole !== 'Agent' && this.userRole !== 'Manager') {
          tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
        } else {
          tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
        }
        fileExportName = 'Low Performers';
        break;
      case 'getPendingByAgent':
        // tableDataConfig = configJson.tableColumnConfig['tableDataConfigPendingByAgent'];
        if (this.userRole !== 'Agent' && this.userRole !== 'Manager') {
          tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
        } else {
          tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
        }
        fileExportName = 'Pending Policies';
        break;
      case 'getPendingByAgentTap':
        // tableDataConfig = configJson.tableColumnConfig['tableDataConfigPendingByAgentTap'];
        if (this.userRole !== 'Agent' && this.userRole !== 'Manager') {
          tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
        } else {
          tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
        }
        fileExportName = 'Pending Policies';
        break;
      case 'getLapsedPolicies':
        // tableDataConfig = configJson.tableColumnConfig['tableDataConfigLapsedPolicies'];
        if (this.userRole !== 'Agent' && this.userRole !== 'Manager') {
          tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
        } else {
          tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
        }
        fileExportName = 'Lapsed Policies';
        break;
      case 'getLapsedPoliciesTap':
        // tableDataConfig = configJson.tableColumnConfig['tableDataConfigLapsedPoliciesTap'];
        if (this.userRole !== 'Agent' && this.userRole !== 'Manager') {
          tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
        } else {
          tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
        }
        fileExportName = 'Lapsed Policies';
        break;
      case 'getAgentSummary':
        // tableDataConfig = configJson.tableColumnConfig['tableDataConfigAgentSummary'];
        if (this.clientType === 'carrier') {
          if (this.userRole === 'Manager') {
            tableColumnDef = [{ "visible": false, "targets": [2] }];
          } else if (this.userRole === 'Agent') {
            tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
          } else {
            tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
          }
        } else {
          tableColumnDef = [{ 'className': 'text-center', "targets": [4] }];
        }
        fileExportName = 'Agent Summary';
        break;
      case 'getAgentSummaryTap':
        // tableDataConfig = configJson.tableColumnConfig['tableDataConfigAgentSummaryTap'];
        tableColumnDef = [{ "visible": false, "targets": [1, 4] }];
        fileExportName = 'Agent Summary';
        break;
      case 'getAgentSummaryDetails':
        // tableDataConfig = configJson.tableColumnConfig['tableDataConfigAgentSummaryDetails'];
        if (this.userRole !== 'Agent' && this.userRole !== 'Manager') {
          tableColumnDef = [{ "visible": false, "targets": [1, 2] }];
        } else {
          tableColumnDef = [{ "visible": false, "targets": [1, 2] }];
        }
        fileExportName = 'Agent Summary Details';
        break;
      case 'getAgentSummaryDetailsTap':
        // tableDataConfig = configJson.tableColumnConfig['tableDataConfigAgentSummaryDetailsTap'];
        if (this.userRole !== 'Agent' && this.userRole !== 'Manager') {
          tableColumnDef = [{ "visible": false, "targets": [2] }];
        } else {
          tableColumnDef = [{ "visible": false, "targets": [2] }];
        }
        fileExportName = 'Agent Summary Details';
        break;
      case 'getMissingAgentDetails':
        // tableDataConfig = configJson.tableColumnConfig['tableDataConfigMissingAgentDetails'];
        fileExportName = 'Missing Agent Details';
        break;
      case 'getPolicyAlerts':
        // tableDataConfig = configJson.tableColumnConfig['tableDataConfigAlertPolicies'];
        fileExportName = 'Policy Alerts';
        break;
      case 'getMissingAgentsData':
        // tableDataConfig = configJson.tableColumnConfig['tableDataConfigMissingAgentsData'];
        fileExportName = 'Missing Agents';
        break;
      case 'getAgencyYearlyReport':
        if (this.clientType === 'carrier') {
          if (this.userRole !== 'Manager') {
            tableColumnDef = [{ "visible": false, "targets": [0, 1, 4, 19, 20, 21] },
            { "className": "dt-left", "targets": 1 }]
          } else {
            tableColumnDef = [{ "visible": false, "targets": [0, 1, 4, 19, 20, 21] },
            { "className": "dt-left", "targets": 1 }]
          }
        } else {
          if (this.companyFilter === 'yes') {
            tableColumnDef = [{ "visible": false, "targets": [0, 1, 4, 19, 20, 21] },
            { "className": "dt-left", "targets": 1 }];
          } else {
            tableColumnDef = [{ "visible": false, "targets": [0, 1, 4, 19, 20, 21] },
            { "className": "dt-left", "targets": 1 }]
          }
        }
        tableDataConfig = [];
        fileExportName = 'Agency Yearly Report';
        break;
      case 'getAgencyYearlyReportSales':
        tableDataConfig = [];
        fileExportName = 'Agency Yearly Report Sales';
        if (this.clientType === 'carrier') {
          tableColumnDef = [{ "visible": false, "targets": [0, 1, 4, 19, 20, 21] },
          { "className": "dt-left", "targets": [1] }]
        } else {
          if (this.companyFilter === 'yes') {
            tableColumnDef = [{ "visible": false, "targets": [0, 1, 4, 19, 20, 21] },
            { "className": "dt-left", "targets": 1 }]
          } else {
            tableColumnDef = [{ "visible": false, "targets": [0, 1, 4, 19, 20, 21] },
            { "className": "dt-left", "targets": 1 }]
          }
        }
        break;
      case 'getManagerQuarterlyIncentiveReport':
        tableColumnDef = [{ "visible": false, "targets": [0, 1, 4, 5, 6, 10, 11, 12] }, { "className": "text-center", "targets": [7] }, { "className": "text-end", "targets": [9] }]
        break;
      case 'getProductionAllowanceIncentiveReport':
        tableColumnDef = [{ "visible": false, "targets": [0, 1, 4, 8, 9, 10] }, { "className": "text-end", "targets": [7] }]
        break;
      case 'getFastStartBonusIncentiveReport':
        tableColumnDef = [{ "visible": false, "targets": [0, 1, 4, 9, 10, 11] }, { "className": "text-center", "targets": [6] }, { "className": "text-end", "targets": [7, 8] }]
        break;
      case 'getFastStartBonusForTrainer':
        tableColumnDef = [{ "className": "text-end", "targets": [3, 4] }]
        break;
      case 'getAgentsForTrainer':
        tableColumnDef = [{ "className": "text-end", "targets": [3, 4, 5, 6] }]
        break;
      case 'getReferralBonusIncentiveReport':
        tableColumnDef = [{ "className": "text-end", "targets": [3, 4] }]
        break;
      case 'getReferredAgents':
        tableColumnDef = [{ "className": "text-end", "targets": [3, 4] }]
        break;
      case 'getAgentBonusIncentiveReport':
        tableColumnDef = [{ "visible": false, "targets": [0, 1] }, { "className": "text-center", "targets": [4] }, { "className": "text-end", "targets": [6] }]
        break;
      case 'getConventionIncentiveReportAris':
        tableColumnDef = [{ "visible": false, "targets": [0, 1, 4, 8, 9, 10] }, { "className": "text-center", "targets": [7] }]
        break;
      case 'getConventionIncentiveReportAmericare':
        tableColumnDef = [{ "visible": false, "targets": [0, 1, 4, 8, 9, 10] }, { "className": "text-end", "targets": [7] }]
        break;
      case 'getRsmBonusIncentiveReport':
        tableColumnDef = [{ "visible": false, "targets": [0, 1, 4, 8, 9, 10] }, { "className": "text-center", "targets": [5] }, { "className": "text-end", "targets": [6, 7] }]
        break;
      case 'getSipIncentiveReport':
        tableColumnDef = [{ "visible": false, "targets": [0, 1, 4, 7, 8, 9] }, { "className": "text-center", "targets": [5] }, { "className": "text-end", "targets": [6, 7] }]
        break;
      case 'getManagerSipIncentiveReport':
        tableColumnDef = [{ "visible": false, "targets": [0, 1, 4, 7, 8, 9] }, { "className": "text-center", "targets": [5] }, { "className": "text-end", "targets": [6, 7] }]
        break;
      case 'getWeeklyAwardIncentiveReport':
        if (this.userRole == 'Manager') {
          tableColumnDef = [{ "visible": false, "targets": [3, 4, 8, 9, 13, 14] }, { "className": "text-center", "targets": [6, 11, 16] }, { "className": "text-end", "targets": [7, 12, 17] }]
        } else {
          tableColumnDef = [{ "visible": false, "targets": [3, 4, 7, 8, 11, 12] }, { "className": "text-center", "targets": [] }, { "className": "text-end", "targets": [6, 10, 14] }]
        }
        break;
      case 'getWeeklyPremiumByAgent':
        tableColumnDef = [{ "visible": false, "targets": [0] }, { "className": "text-center", "targets": [] }, { "className": "text-end", "targets": [] }];
        break;
      case 'getAgencyAnalyticsReport':
        // tableDataConfig = [];
        if (this.clientType === 'carrier') {
          tableColumnDef = [{ "visible": false, "targets": [0, 1, 2, 7, 8, 9] }]
        } else {
          if (this.companyFilter === 'yes') {
            tableColumnDef = [{ "visible": false, "targets": [0, 1, 2, 4, 7, 8, 9] }]
          } else {
            tableColumnDef = [{ "visible": false, "targets": [0, 1, 2, 7, 8, 9] }]
          }
        }
        fileExportName = 'Agency Analytics Report';
        break;
      case 'getAgentHighestMonthReport':
        // tableDataConfig = [];
        if (this.clientType === 'carrier') {
          tableColumnDef = [{ "targets": [0, 1], "visible": false }];
        } else {
          if (this.companyFilter === 'yes') {
            tableColumnDef = [{ "targets": [0, 1, 2], "visible": false }];
          } else {
            tableColumnDef = [{ "targets": [0, 1], "visible": false }];
          }
        }

        fileExportName = 'Agent Highest Month Report';
        break;
      case 'getNewAgentsReport':
        // tableDataConfig = [];
        if (this.clientType === 'carrier') {
          tableColumnDef = [{ "targets": [0, 1], "visible": false }];
        } else {
          if (this.companyFilter === 'yes') {
            tableColumnDef = [{ "targets": [0, 1, 2], "visible": false }];
          } else {
            tableColumnDef = [{ "targets": [0, 1], "visible": false }];
          }
        }
        fileExportName = 'New Agents Report';
        break;
      case 'getAgentsIncDecReport':
        // tableDataConfig = [];
        if ($('#toggleReport .toggleActive').attr('type') === '$') {//sale
          if (this.clientType === 'carrier') {
            tableColumnDef = [{ "targets": [0, 1], "visible": false }, { 'className': 'text-end', "targets": [4, 5, 6] }];
          } else {//agency
            if (this.companyFilter === 'yes') {
              tableColumnDef = [{ "targets": [0, 1, 2], "visible": false }, { 'className': 'text-end', "targets": [5, 6, 7] }];
            } else {
              tableColumnDef = [{ "targets": [0, 1], "visible": false }, { 'className': 'text-end', "targets": [4, 5, 6] }];
            }
          }
        } else { //policy
          if (this.clientType === 'carrier') {
            tableColumnDef = [{ "targets": [0, 1], "visible": false }];
          } else {//agency
            if (this.companyFilter === 'yes') {
              tableColumnDef = [{ "targets": [0, 1, 2], "visible": false }];
            } else {
              tableColumnDef = [{ "targets": [0, 1], "visible": false }];
            }
          }
        }
        fileExportName = 'Agents IncDec Report';
        break;
      case 'getPolicies':
        // tableDataConfig = [];
        let subTabId = this.localStorageService.get('activeSubTab')['id']
        if(subTabId==27) {
          tableColumnDef = [{ "targets": [3], "className": "text-end" }];
        } else {
          tableColumnDef = [{ "targets": [2], "className": "text-end" }];
        }
        fileExportName = 'Policies';
        break;
      case 'getPendingPolicies':
        // tableDataConfig = [];
        tableColumnDef = [{ "targets": [2], "className": "text-end" }];
        fileExportName = 'Pending Policies';
        break;
      case 'getYearlyPolicies':
        // tableDataConfig = [];
        tableColumnDef = [{ "targets": [2], "className": "text-end" }];
        fileExportName = 'Yearly Policies';
        break;
      case 'getStatePoliciesData': case 'getStatePoliciesDataReport': case 'getStateSaleData': case 'getStatePoliciesDataReport':
        tableColumnDef = [{ "targets": [0], "className": "text-start" }];
        break;
      case 'getAgentYearlyReport':
        // tableDataConfig = [];
        tableColumnDef = [];
        fileExportName = 'Agent Yearly Report';
        break;
      case 'getAgentYearlyReportSales':
        // tableDataConfig = [];
        tableColumnDef = [];
        fileExportName = 'Agent Yearly Report Sales';
        break;
      case 'getAgentReportPolicies':
        // tableDataConfig = [];
        tableColumnDef = [{ "targets": [2], "className": "text-end" }];
        fileExportName = 'Agent Report';
        break;
      case 'getPoliciesForMissingAgent':
        tableDataConfig = [];
        tableColumnDef = [{ "targets": [2], "className": "text-end" }];
        fileExportName = 'Missing Agent Policies';
        break;
      case 'getAgentApplicationDetails':
        tableDataConfig = [];
        if (this.clientType === 'carrier') {
          tableColumnDef = [{ "visible": false, "targets": [2, 3] }]
        } else {
          if (this.userRole === 'Agent') {
            tableColumnDef = [{ "visible": false, "targets": [2, 3] }]
          } else {
            tableColumnDef = [{ "visible": false, "targets": [2, 3] }]
          }
        }
        fileExportName = 'Applications Detail';
        break;
      case 'getApplications':
        tableDataConfig = [];
        if (this.clientType === 'carrier') {
          tableColumnDef = [{ "visible": false, "targets": [1] }]
        } else {
          if (this.userRole === 'Agent') {
            tableColumnDef = [{ "visible": false, "targets": [1] }]
          } else {
            tableColumnDef = [{ "visible": false, "targets": [1] }]
          }
        }
        fileExportName = '';
        break;
      case 'getAgentApplicationSummaryRollUp':
        tableDataConfig = [];
        if (this.clientType === 'carrier') {
          tableColumnDef = [{ "visible": false, "targets": [1, 2] }]
        } else {
          if (this.userRole === 'Agent' && this.companyFilter === 'yes') {
            tableColumnDef = [{ "visible": false, "targets": [1, 2] }]
          } else {
            tableColumnDef = [{ "visible": false, "targets": [1, 2] }]
          }
        }
        fileExportName = 'Applications Summary';
        break;
      case 'getAgentApplicationSummaryRollUpDrill':
        tableDataConfig = [];
        if (this.clientType === 'carrier') {
          if (this.userRole === 'Manager') {
            tableColumnDef = [{ "visible": false, "targets": [1, 2] }];
          } else {
            tableColumnDef = [{ "visible": false, "targets": [0, 2, 3] }];
          }
        } else {
          if (this.userRole === 'Manager') {
            tableColumnDef = [{ "visible": false, "targets": [1, 2] }];
          } else {
            tableColumnDef = [{ "visible": false, "targets": [0, 2, 3] }];
          }
        }
        fileExportName = 'Applications Summary';
        break;
      case 'getAgentSummaryPersonalDetails':
        tableDataConfig = [];
        if (this.clientType === 'carrier') {
          if (this.userRole === 'Manager') {
            tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
          } else {
            tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
          }
        } else {
          if (this.userRole === 'Manager') {
            tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
          } else {
            tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
          }
        }
        fileExportName = 'Agents Summary List';
        break;
      case 'getAgentContactDetails':
        tableDataConfig = [];
        fileExportName = 'Agent Contact List';
        break;
      case 'getAgentSummaryPersonalDetailsRollup':
        tableDataConfig = [];
        tableColumnDef = [{ "visible": false, "targets": [2, 3] }];
        fileExportName = 'Agents Summary Roll Up';
        break;
      case 'getAgentSummaryPersonalDetailsRollupDrill':
        tableDataConfig = [];
        if (this.clientType === 'carrier') {
          if (this.userRole === 'Manager') {
            tableColumnDef = [{ "visible": false, "targets": [1, 2] }];
          } else {
            tableColumnDef = [{ "visible": false, "targets": [1, 2] }];
          }
        } else {
          if (this.userRole === 'Manager') {
            tableColumnDef = [{ "visible": false, "targets": [1, 2] }];
          } else {
            tableColumnDef = [{ "visible": false, "targets": [1, 2] }];
          }
        }
        fileExportName = 'Agents Summary Roll Up';
        break;
      case 'getSubmittedMonthlyReport':
        fileExportName = 'Life & Annuity Submitted Monthly Report';
        break;
      case 'getSubmittedWeeklyReport':
        fileExportName = 'Life & Annuity Submitted Weekly Report';
        break;
      case 'getAgentTapDownlineReport':
        tableColumnDef = [{ "visible": false, "targets": [0, 3, 4, 8, 9, 10] }];
        fileExportName = 'Agent Tap Downline Report';
        break;
      case 'getAgentTapDownlineReportSales':
        tableColumnDef = [{ "visible": false, "targets": [0, 3, 4, 8, 9, 10] }];
        fileExportName = 'Agent Tap Downline Sale Report';
        break;
      case 'getIncentiveProgramAgents':
        tableColumnDef = [{ "targets": [2, 3], "className": "text-center" }];
        fileExportName = 'Incentive Report';
        break;
      case 'getAgentPaymentDetails':
        tableColumnDef = [{ "visible": false, "targets": [2, 3] }, { "targets": [6], "className": "text-center" }, { "className": "text-end", "targets": [7] }];
        fileExportName = 'Payment Detail Report';
        break;
      case 'getAgentPaymentSummary':
        tableColumnDef = [{ "visible": false, "targets": [2] }, { "targets": [4], "className": "text-center" }];
        fileExportName = 'Payment Summary Report';
        break;
      case 'getAgentPaymentSummaryDetails':
        tableColumnDef = [{ "visible": false, "targets": [1, 2] }, { "targets": [5], "className": "text-center" }, { "className": "text-end", "targets": [6] }];
        fileExportName = 'Payment Summary Detail';
        break;
      case 'getPointsDistribution':
        tableColumnDef = [{ "targets": [2, 4], "className": "text-center" }];
        break;
      case 'getRepLast4WeekProduction':
        tableColumnDef = [{ "visible": false, "targets": [] }, { "className": "text-end", "targets": [6, 7] }, { "className": "text-center", "targets": [3, 4, 5] }]
        break;
      case 'getBrokerageBucks':
        tableColumnDef = [{ "targets": [2, 3], "className": "text-center" }];
        fileExportName = 'Brokerage Bucks Incentive Report';
        break;
    }

    let configObj = {
      // tableDataConfig: tableDataConfig,
      tableColumnDef: tableColumnDef,
      fileExportName: fileExportName
    }
    return configObj;
  }

  formatProductList(data) {
    var products = [];
    data.forEach(function (product) {
      products.push({ category: product.category });
    });
    return products;
  }
}
