import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { configJson, DashboardConfigService } from '../shared/services/dashboard-config.service';
import { Router } from '@angular/router';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private baseUrl = environment.baseURL;
  private currentUserSubject: BehaviorSubject<object>;
  SSOLogin: string;
  isAuthenticatedValue = new Subject<boolean>();
  constructor(
    private router: Router, private http: HttpClient,
    private dashboardConfigService: DashboardConfigService,
    ) {
  }

  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    Response: 'text' as 'json'
  };

  public async isAuthenticated(route) {
    let userData = localStorage.getItem('userInfo');
    !configJson ? await this.dashboardConfigService.getDashboardConfig():'';
    this.SSOLogin = configJson.ssoLogin;
    return new Promise((resolve, reject) => {
      if (userData && JSON.parse(userData)) {
        resolve(true);
      } else {
        if (this.SSOLogin === 'yes') {
          this.SAMLloginValidate(route['queryParams']).subscribe((res: any) => {
            if (res) {
              let userData = JSON.stringify(res.userInfo[0]);
              localStorage.setItem('userInfo', userData);
              localStorage.setItem('_t', JSON.stringify(res.authToken));
              localStorage.setItem('_rt', JSON.stringify(res.refreshToken));
              this.checkStripeSubscription().subscribe((stripeRes:any) =>{
                if(stripeRes && stripeRes['url'] === 'dashboard'){
                  resolve(true);
                } else {
                  this.router.navigate(['subscribe/' + res['url']],{});
                }
              })
            } else {
              reject(false);
            }
            // this.currentUserSubject.next(res);
          });
        } else {
          reject(false);
        }
      }
    })
  }

  onValidate(): Observable<any> {
    return this.isAuthenticatedValue.asObservable();
  }

  public SAMLloginValidate(reqObj: object) {
    return this.http.post<[]>(`samlLoginValidate`, reqObj, this.httpOptions);
  }

  public setUserInfo(user) {
    localStorage.setItem('userInfo', JSON.stringify(user));
    // this.currentUserSubject.next(user);
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  public getClientConfig() {
    return this.http.get(`${this.baseUrl}getClientConfig`);
  }

  /* stripe */
  public getUserData() {
    return this.http.get(`${this.baseUrl}getUserData`);
  }

  public verifyCoupon(reqObj) {
    return this.http.get(`${this.baseUrl}verifyCoupon?coupon=${reqObj['coupon']}&applicationType=${reqObj['applicationType']}&subscriptionType=${reqObj['subscriptionType']}&subscriptionCount=${reqObj['subscriptionCount']}`, this.httpOptions);
  }

  public getSubscriptionPriceDetail(reqObj) {
    return this.http.get(`${this.baseUrl}getGroupRegistrationPrice?subscriptionType=${reqObj['subscriptionType']}&quantity=${reqObj['quantity']}`, this.httpOptions);
  }

  public stripeCharge(reqObj) {
    return this.http.post(`${this.baseUrl}charge`, reqObj, this.httpOptions)
  }

  public getsubscriptionDetails() {
    return this.http.get(`${this.baseUrl}getSubscription`);
  }

  public checkStripeSubscription() {
    return this.http.post<[]>(`${this.baseUrl}getUserSubscription`, {apptype:"web"}, this.httpOptions)
  }

  public cancelStripeSubscription(reqObj) {
    return this.http.post<[]>(`${this.baseUrl}cancelSubscription`, reqObj, this.httpOptions)
  }

  public validate(email, password) {
    return this.http.post(`${this.baseUrl}authenticate`, { 'username': email, 'password': password })
  }

  public checkOptionalEmail(reqObj: object) {
    return this.http.post(`${this.baseUrl}checkOptionalEmail`, reqObj)
  }

  public checkOptionalNPN(reqObj: object) {
    return this.http.post(`${this.baseUrl}isValidNPN`, reqObj)
  }

  public checkOptionalEmailVerify(reqObj: object) {
    return this.http.get(`${this.baseUrl}checkOptionalEmailVerify?primaryEmail=${reqObj['primaryEmail']}&secondaryEmail=${reqObj['secondaryEmail']}`, { responseType: 'text' as 'json' });
  }

  checkOTP(res) {
    var isEulaApplicable;
    if (res[0]) {
      if (res[0].is_eula_applicable && res[0].is_eula_accepted !== 1) {
        $('#eulaAgreement').show();
        isEulaApplicable = 1;
      } else {
        $('#eulaAgreement').hide();
        isEulaApplicable = 0;
      }
      if (res[0].is_blocked === 1) {
        res = res[0].isblockedvalid ? '' : 'User is locked. Please contact your Helpdesk. Your account will be unlocked after 15 mins.';
      } else if (res[0].invalid_login_attempts >= 3) {
        res = 'You have exceeded the limit of invalid login attempts(3). Please enter the OTP sent to your email id.';
        $('#otpContainer').show();
      } else if (res[0].is_blocked === 0) {
        res = 'User unblocked';
      } else {
        res = '';
        $('#otpContainer').hide();
      }
    } else if (!res) {
      res = 'User not found';
    } else if (res.length === 0) {
      res = 'User not found';
    }
    return { msg: (res ? res : ''), isEulaApplicable: (isEulaApplicable ? isEulaApplicable : false) }
  }

  public checkEmailOtp(reqObj: object) {
    return this.http.post(`${this.baseUrl}checkIfOtpApplicable`, reqObj).pipe(map(res => {
      return this.checkOTP(res);
    }))
  }

  public isCookieOtpValid(user: any) {
    const paramObj = {
      'isCookieSet': '',
      'username': user.username,
      'npnFlag': user.npnFlag,
      'otp': '',
      'password': user.password
    };
    return this.http.post(`${this.baseUrl}isCookieOtpValid`, paramObj)
  }

  public login(reqObj: object) {
    return this.http.post(`${this.baseUrl}login`, reqObj)
  }

  public checkRefreshToken() {
    return this.http.post(`${this.baseUrl}refresh-auth-token`, {})
  }

  public loginUsingOtp(reqObj: object) {
    return this.http.post(`${this.baseUrl}loginUsingOtp`, reqObj)
  }

  public getCookieOtp(reqObj: object) {
    return this.http.post(`${this.baseUrl}getCookieOtp`, reqObj)
  }

  public acceptEula(reqObj: object) {
    return this.http.get(`${this.baseUrl}acceptEula?param1=${reqObj['param1']}&param2=${reqObj['param2']}`, { responseType: 'text' as 'json' })
  }

  public setUserOtp(reqObj: object) {
    return this.http.get(`${this.baseUrl}setUserOtp?param1=${reqObj['param1']}&param2=${reqObj['param2']}&param3=${reqObj['param3']}`, { responseType: 'text' as 'json' });
  }

  public sendOtp(reqObj: object) {
    return this.http.post(`${this.baseUrl}sendOtp`, reqObj)
  }

  public resetOtp(reqObj: object) {
    return this.http.post(`${this.baseUrl}resetOtp`, reqObj)
  }

  public setCookieOtp(reqObj: object) {
    return this.http.post(`${this.baseUrl}setCookieOtp`, reqObj)
  }

  public resetUser(reqObj: object) {
    return this.http.post(`${this.baseUrl}resetUser`, reqObj)
  }

  public checkForgotEmailIsValid(reqObj: object) {
    return this.http.post(`${this.baseUrl}checkForgotEmailIsValid`, reqObj, { responseType: 'text' as 'json' })
  }

  public sendForgotPassword(reqObj: object) {
    return this.http.post(`${this.baseUrl}forgot-pasword`, reqObj, { responseType: 'text' as 'json' })
    // .subscribe(res =>{
    //   return { msg: 'Please check your mailbox for forgot password link to change the password'};
    // })
  }

  // public forgotPasswordChange(reqObj: object) {
  //   return this.http.get(`${this.baseUrl}forgotPasswordChange?param1=${reqObj['param1']}&param2=${reqObj['param2']}&forgot_password_key=${reqObj['forgot_password_key']}`, { responseType: 'text' as 'json' })
  // }

  public forgotPasswordChange(body: { [key: string]: any }) {
    return this.http.post<{ [key: string]: any }>(`${this.baseUrl}forgotPasswordChange`, body);
  }

  public validateChangepasswordUser(reqObj: object) {
    return this.http.get(`${this.baseUrl}validateChangepasswordUser?param1=${reqObj['param1']}&param2=${reqObj['param2']}&param3=${reqObj['param3']}&npnFlag=${reqObj['npnFlag']}`)
  }

  public changepassword(reqObj: object) {
    return this.http.get(`${this.baseUrl}changepassword?param1=${reqObj['param1']}&param2=${reqObj['param2']}&param3=${reqObj['param3']}&npnFlag=${reqObj['npnFlag']}&param4=${reqObj['param4']}`, { responseType: 'text' as 'json' })
  }

  public checkForResetPassFlag(body: { [key: string]: any }) {
    return this.http.post<{ [key: string]: any }>(`${this.baseUrl}check-pwd-link-validity`, body);
  }

  public updateCardDetails(reqObj: any) {
    return this.http.post<[]>(`${this.baseUrl}updateCard`, reqObj)
  }

  logout() {
    return this.http.post<{}>(`${this.baseUrl}logout`, this.httpOptions)
  }

}