import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { object } from 'underscore';
import { CommonService } from '../../shared/services/common.service';
import { AuthService } from '../auth.service';
import { LocalstorageService } from 'src/app/shared/services/localstorage.service';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {
  SSOLogin: string = '';
  showLogoutPage: boolean = false;
  logo: any;
  app_version: number;

  constructor(private authService: AuthService,
     private router: Router,
      private commonService: CommonService,
      private localStorageService: LocalstorageService
      ) { }

  ngOnInit(): void {
    this.SSOLogin = this.commonService.SSOLogin;
    this.logo = this.commonService.getLogoPath();
    this.getClientConfig();
    this.showLogoutPage = CommonService.showLogoutPage;
    // this.authService.logout().subscribe(response => {
      // remove user from local storage to log user out
      this.commonService.resetSelector();
      this.localStorageService.remove("_t");
      this.localStorageService.remove("userTabInfo");
      this.localStorageService.remove("userInfo");
      this.localStorageService.remove("activeTabId");
      this.localStorageService.remove("activeSubTab");
      // this.localStorageService.remove('_rt');
      // localStorage.clear();
      // if (response['status'] === 'Success') {
        this.showLogoutPage = CommonService.showLogoutPage = false;
        if (this.SSOLogin && this.SSOLogin.includes('no')) {
          this.router.navigate(['/login'], { queryParams: {} });
        } else {
          this.showLogoutPage = CommonService.showLogoutPage = true;
          // this.router.navigate(['/logout'], { queryParams: { } });
        }
      // }
    // });
  }

  getClientConfig() {
    this.authService.getClientConfig().subscribe(response => {
      this.app_version = response[0].app_version;
    })
  }

}
