import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { SelectorsComponent } from '../shared/components/selectors/selectors.component';
import { AlertTickerComponent } from '../shared/components/alert-ticker/alert-ticker.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CoreRoutingModule } from './core-routing.module';
import { FormsModule } from '@angular/forms';
import { FlashMessagesModule } from 'angular2-flash-messages';
import { CardsComponent } from './cards/cards.component';
import { NumberDirective } from '../shared/directives/numbers-only.directive';
import { UiSwitchModule } from 'ngx-ui-switch';

@NgModule({
    declarations: [
        HeaderComponent,
        FooterComponent,
        HomeComponent,
        SelectorsComponent,
        AlertTickerComponent,
        CardsComponent,
        NumberDirective
    ],
    imports: [     
        CommonModule,
        FormsModule,
        BsDatepickerModule.forRoot(),
        DatepickerModule.forRoot(),
        FlashMessagesModule.forRoot(),
        NgxSpinnerModule,
        CoreRoutingModule,
        UiSwitchModule,
    ]
})
export class CoreModule { }
