import { Injectable, Inject } from '@angular/core';
import { CommonService } from './common.service';
import * as FileSaver from 'file-saver';
import { configJson } from '../../shared/services/dashboard-config.service';
import { LocalstorageService } from './localstorage.service';
import { CommonConstant } from '../constants/common-constant';
import { ApiConstant } from '../constants/api-constant';
import { TableService } from '../components/table/table.service';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class ExportService {

    userInfo: any;
    activeTabId: number;
    userRole: string = '';
    isTAPRequired: string = '';
    clientType: string = '';
    companyFilter: string = '';
    activeSubTab: { id: number, api: string };
    private propertiesService: TableService;
    private propertiesPipe: DatePipe;
    statusTimeout = 7000;

    constructor(@Inject(TableService) propertiesService: TableService,
        @Inject(DatePipe) propertiesPipe: DatePipe,
        private commonService: CommonService,
        private localStorageService: LocalstorageService) {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.userRole = this.userInfo['role'];
        this.isTAPRequired = this.userInfo['isTAPRequired'];
        this.activeTabId = this.localStorageService.get('activeTabId');
        this.activeSubTab = this.localStorageService.get('activeSubTab');
        this.companyFilter = this.userInfo['companyFilter'];
        this.clientType = configJson.clientType;
        this.propertiesService = propertiesService;
        this.propertiesPipe = propertiesPipe;
    }

    callExportToCSV(customObj, customExportApiFlag, fileName?: string, apiParams: { [key: string]: any } = {}) {
        this.activeTabId = this.localStorageService.get('activeTabId');
        this.activeSubTab = this.localStorageService.get('activeSubTab');

        // Select all input fields with the class name "col-search-input"
        const searchInputFields = document.querySelectorAll('.col-search-input');
        // Create an array to store the values
        const searchValues = [];
        // Iterate over the input fields and retrieve their values
        searchInputFields.forEach((input:HTMLInputElement) => {
            searchValues.push(input.value);
        });
        let nonEmptySearchValues:any = searchValues.filter(value => value !== '');
        const newLength = nonEmptySearchValues.length ? nonEmptySearchValues.unshift('_'): nonEmptySearchValues;
        
        this.commonService.showLoader();
        var params = Object.keys(apiParams).length == 0 ? { ...this.commonService.getParameters() } : apiParams;
        if (JSON.parse(localStorage.getItem('activeSubTab'))?.id == 27 && JSON.parse(localStorage.getItem('activeTabId')) == 5) {
            console.log(this.commonService.incentive_from_date, this.commonService.incentive_to_date);
            this.commonService.incentive_from_date ? params['param3'] = this.commonService.incentive_from_date : '';
            this.commonService.incentive_to_date ? params['param4'] = this.commonService.incentive_to_date : '';
        }
        params['searchObj'] = this.commonService.tableSearchObj;
        let tableConfig = this.propertiesService.getTableConfigObj();
        params['exportExcel'] = true;
        if ($('#popupContainer').length === 1 && params['popupSearchObj'] instanceof Array) {
            params['searchObj'] == undefined ? params['searchObj'] = [] : '';
            for (let popupsearchobj of params['popupSearchObj']) {
                let temp = params['searchObj'].find(e => e.column == popupsearchobj.column);
                if (!temp) {
                    params['searchObj'].push(popupsearchobj);
                }
            }
        } else {
            params['searchObj'] = params['searchObj'];
        }
        let orderByConfig = configJson.tableColumnConfig.orderByConfig;
        orderByConfig = orderByConfig.filter((el) => {
            return el['role'] === this.userRole;
        });
        params['orderBy'] = orderByConfig
        // if(tableConfig.api_name !== 'getPolicyAlerts'){
        //     let tableDataConfig = this.tableService.getTableDataConfig(obj.api);
        //     params.tableDataConfig = = tableDataConfig['tableDataConfig'].filter(obj => {
        //         return obj.role === JSON.parse(localStorage.getItem('userInfo'))['role'];
        //     });
        // }
        // if (this.activeTabId === 3 && $('#pageSubTabs .active').attr('id') !== 'subtab6') {
        //     if ($scope.sapOrTapToggle.alertsTabTapVlue === 'tap') {
        //         params.orderBy = orderByConfigTap.filter(function (el) {
        //             return el.role === $scope.userRole;
        //         });
        //     } else {
        //         params.orderBy = orderByConfig.filter(function (el) {
        //             return el.role === $scope.userRole;
        //         });
        //     }
        // }
        if (customExportApiFlag) {
            var api = customObj['apiName'];
            var fileExportName = fileName ? fileName : customObj['fileExportName'];
            if (api === 'getApplicationDetails') {
                params['appId'] = customObj['appId']
                params['param11'] = 'application'
            }
        } else {
            var api = tableConfig['apiName'] === '' ? params['url'] : tableConfig['apiName'];
            var getTableConfig = this.propertiesService.getTableDataConfig(api);
            var fileExportName = fileName ? fileName : getTableConfig['fileExportName'];
            if ($('#popupContainer').length === 1) {
                fileExportName = fileName ? fileName : $('.modal-title').text();
            }

            if (this.activeTabId === 5) {
                if (api === ApiConstant.getPolicyAlerts) {
                    fileExportName = fileName ? fileName : 'UW Report';
                }
            }

            if (this.activeTabId === 4 && api === ApiConstant.getPolicyAlerts) {
                let currentTime = this.propertiesPipe.transform(new Date(), 'h:mm a');
                fileExportName = fileName ? fileName : params['selectedInfolabalValue'] + ' Report ' + currentTime + ' ' + params['alertDropDownValue'];
            }

            if (api === 'getAgentYearlyReport') {
                var breadcrumbLILength = $('#breadcrumb li').length;
                params['agentId'] = $('#breadcrumb li:nth-child(' + breadcrumbLILength + ') a').attr('agentid') === undefined ? '' : $('#breadcrumb li:nth-child(' + breadcrumbLILength + ') a').attr('agentid');
                params['level'] = $('#breadcrumb li:nth-child(' + breadcrumbLILength + ') a').attr('agentlevel') === undefined ? 'All' : $('#breadcrumb li:nth-child(' + breadcrumbLILength + ') a').attr('agentlevel');
            }
        }
        if ([27,37].includes(this.activeSubTab.id) || [ApiConstant.getFastStartBonusIncentiveReport, ApiConstant.getManagerQuarterlyIncentiveReport, ApiConstant.getAgentBonusIncentiveReport, ApiConstant.getAgentBonusIncentiveReport || ApiConstant.getProductionAllowanceIncentiveReport, ApiConstant.getConventionIncentiveReportAris, ApiConstant.getConventionIncentiveReportAmericare].includes(api)) {
            this.commonService.getParameters()['start_date_temp'] ? params['param3'] = this.commonService.getParameters()['start_date_temp'] : '';
            this.commonService.getParameters()['end_date_temp'] ? params['param4'] = this.commonService.getParameters()['end_date_temp'] : '';
        }
        this.exportToCSV(api, params, '', fileExportName, nonEmptySearchValues);
    }

    exportToCSV = function (api, params, agentName, csvFileName, nonEmptySearchValues) {
        let that = this;
        // that.checkLoaderFlag = false;
        // if(api === 'getAgentYearlyReport' || api === 'getAgentYearlyReportSales'){
        //     var loader = "<div id='customLoader' style='position: absolute;margin-top: 22px;'> <span class='buttonload-label label label-important' >Your report is downloading...</span>"+
        //     "<button class='buttonload'><i class='fa fa-spinner fa-spin'></i></button></div>";
        //     $('#table2_wrapper').prepend(loader);
        //     that.checkLoaderFlag = true;
        // }
        // if(api === 'getAgentSummaryPersonalDetails'){
        //     params.isExport = true;
        //     params.tableDataConfig = getTableDataConfig(api+'Export').filter(function (obj) {
        //         return obj.role === that.userRole;
        //     });
        // }
        // if(api === 'getApplications'){
        //     params.isExport = true;
        // }
        // if(api === 'getPolicyAlerts'){
        //     if(params.selectedInfolabalValue === 'Recent Applications'){
        //         params.tableDataConfig = tableDataConfigSubmittedApplicationsAlerts;
        //     } else if(params.selectedInfolabalValue === 'Underwriting Changes'){
        //         params.tableDataConfig = tableDataConfigUnderwritingChangesAlerts;
        //     }
        // }
        // if(api === 'getBookOfBusiness'){
        //     params.tableDataConfig = tableDataConfigBookOfBusiness;
        //     csvFileName = 'Book Of Business';
        // }
        let body = { ...params, 'download': true }
        that.commonService.hideLoader();
        let msgDiv = this.createDownloadMessage();
        document.body.appendChild(msgDiv);
        $.ajax({
            type: 'POST',
            url: environment.baseURL + api,
            contentType: 'application/json',
            data: JSON.stringify(body),
            headers: {
                authorization: JSON.parse(localStorage.getItem("_t")),
                brinstance: encodeURIComponent(this.commonService.encrypt(this.localStorageService.get('browserInstance'), environment.browserKey))
              }
        }).done(function (result) {
            // document.body.removeChild(msgDiv);
            console.log(result);
            params.isExport = false;
            if (api === 'getAgentYearlyReport' || api === 'getAgentYearlyReportSales') {
                /* this line is only needed if you are not adding a script tag reference */
                //  if(typeof XLSX == 'undefined') XLSX = require('xlsx');
                /* add to workbook */
                // let is_iPad = navigator.userAgent.match(/iPad/i) !== null;
                let fileName = "AgentReport"+ nonEmptySearchValues.join('_')+".xlsx";
                // if (is_iPad) {
                //     // Chrome, Firefox, and Chrome and Safari on iOS
                //     let dataUrl = window.origin + `/download/${result.serverFilename}/${fileName}`;
                //     let open = window.open(dataUrl);
                // } else {
                //     FileSaver.saveAs(window.origin + `/download/${result.serverFilename}/${fileName}`, fileName);
                // }
                if (result.serverFilename?.length > 0) {
                    setTimeout(() => that.getFile(result.serverFilename, fileName, msgDiv), that.statusTimeout);
                    return;
                }
                let wb = XLSX.utils.book_new();
                let resObj = {};
                if (result.carrier.length && result.data) {
                    if (result.carrier.length == 1) {
                        result.carrier.forEach((ele, index) => {
                            resObj[ele] = result.data.find(element => element.key === ele);
                            if (resObj[ele] && resObj[ele]['values'].length > 0) {
                                /* make the worksheet */
                                var ws = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(resObj[ele]['values'])));

                                var k = resObj[ele]['key'].replace(/[\/]/gi, ' ');
                                XLSX.utils.book_append_sheet(wb, ws, k);
                            }
                        })
                    } else {
                        result.carrier.forEach((ele, index) => {
                            resObj[ele] = result.data.find(element => element.key === ele);
                            if (resObj[ele] && resObj[ele]['values'].length > 0) {
                                /* make the worksheet */
                                var ws = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(resObj[ele]['values'])));

                                var k = resObj[ele]['key'].replace(/[\/]/gi, ' ');
                                XLSX.utils.book_append_sheet(wb, ws, k);
                            }
                        })
                    }
                    // result.data.forEach(ele=>{
                    //     if(ele['values'].length >0){
                    //         /* make the worksheet */
                    //         var ws = XLSX.utils.json_to_sheet(JSON.parse(JSON.stringify(ele['values'])));

                    //         var k = ele['key'].replace(/[\/]/gi, ' ');
                    //         XLSX.utils.book_append_sheet(wb, ws, k);
                    //     }
                    // })

                    if (wb.SheetNames.length > 0) {
                        XLSX.writeFile(wb, "AgentReport"+ nonEmptySearchValues.join('_')+".xlsx");
                    }
                }
                // that.checkLoaderFlag = false;
                $('#customLoader').remove();
                that.commonService.hideLoader();
                document.body.removeChild(msgDiv);
                /* generate an XLSX file */

                //     /* generate an XLSX file */
                //     // var file = XLSX.writeFile(wb, )
                //     const temp = new Promise((resolve, reject) => {
                //      // the interface wasn't clearly documented, but this reasonable guess worked...
                //        return XLSX.writeFileAsync("Report.xlsx", wb, (error, result) => {
                //             console.log("Error", error);
                //             console.log("Data", result)
                //                 if(error){
                //                     reject(error);
                //                     // res.end();
                //                 } else {
                //                     resolve(result);
                //                     // res.end();
                //                 }
                //       })
                //    });
            } else {
                let newDateValue = new Date();//$scope.forYear
                let yearValue = newDateValue.getFullYear();
                let agencyId = params.agentId ? 'Agency Id: ' + ',' + params.agentId : '';
                if (agencyId === '') {
                    agentName = '';
                }
                let agencyName = 'Agency Name: ' + ',' + agentName;

                // var csv = agencyId + '\n' + agencyName + '\n' + 'Period: ' + ',' + yearValue + '\n\n' + JSON2CSV(result);
                let csv = '';
                // if(api === 'getApplicationDetails'){
                //     csv = that.JSON2CSV(applicationDetailInfo);
                // } else
                if (api === 'getBookOfBusiness') {
                    $('#reportTitle h4 b').html('Book Of Business Data is Downloaded!');
                }
                let fileName = '';
                if (!csvFileName) {
                    if ($('#breadcrumb li').length === 1) {
                        fileName = 'Agency Report' + nonEmptySearchValues.join('_') + '.csv';
                    } else {
                        var breadcrumbLILength = $('#breadcrumb li').length;
                        if (api === 'getAgencyYearlyReport' || api === 'getAgencyYearlyReportSales') {
                            var agentName = $('#breadcrumb li:nth-child(' + breadcrumbLILength + ') a').attr('agentname');
                            fileName = 'Agency Report for ' + agentName + '.csv';
                        } else {
                            fileName = !agentName ? 'Agency Report for ' + agentName + nonEmptySearchValues.join('_') + '.csv' : 'Agency Report' + nonEmptySearchValues.join('_') + '.csv'
                        }
                    }
                } else {
                    fileName = csvFileName + nonEmptySearchValues.join('_') + '.csv';
                }
                if (result.serverFilename?.length > 0) {
                    setTimeout(() => that.getFile(result.serverFilename, fileName, msgDiv), that.statusTimeout);
                    return;
                }
                // works on IE and Edge to save file, would also work on Chrome, Firedox for desktop
                // var is_iPad = navigator.userAgent.match(/iPad/i) !== null;
                // if (is_iPad) {
                //     // Chrome, Firefox, and Chrome and Safari on iOS
                //     let dataUrl = window.origin + `/download/${result.serverFilename}/${fileName}`;
                //     let open = window.open(dataUrl);
                // } else {
                //     FileSaver.saveAs(window.origin + `/download/${result.serverFilename}/${fileName}`, fileName);
                //     // window.open(window.origin + `/download/${result.serverFilename}/${csvFileName}.csv`);
                // }
                if (api === 'getMissingAgentsData' || api === 'getBookOfBusiness' || api === 'agentNpnDownlineHierarchyDownload' || api === 'getStateSaleDataReport' || api === 'getStatePoliciesDataReport' ||
                    api === 'getStatePoliciesData' || api === 'getStateSaleData' ||
                    api === 'getNewAgentsReport' || api === 'getAgentYearlyReport' || api === 'getAgentYearlyReportSales' || api === 'downloadTapDownlineAgentHierarchy') {
                    csv = that.JSON2CSV(result);
                } else if (api === 'getAgentWeeklyFastStartBonus') {
                    csv = that.JSON2CSV(result, params, api);
                } else {
                    csv = that.JSON2CSV(result.data, params, api);
                }
                var blob = new Blob(['\ufeff', csv]);
                var reader: any = new FileReader();
                reader.onload = function () {
                    var is_iPad = navigator.userAgent.match(/iPad/i) !== null;
                    if (is_iPad) {
                        // Chrome, Firefox, and Chrome and Safari on iOS
                        var dataUrl = reader.result;
                        var open = window.open(dataUrl);
                        if (open === null || typeof (open) === 'undefined') {
                            var blobUrl = URL.createObjectURL(blob);
                            window.location.href = blobUrl;
                        }
                    } else {
                        var fileName = '';
                        if (!csvFileName) {
                            if ($('#breadcrumb li').length === 1) {
                                fileName = 'Agency Report' + nonEmptySearchValues.join('_') + '.csv';
                            } else {
                                var breadcrumbLILength = $('#breadcrumb li').length;
                                if (api === 'getAgencyYearlyReport' || api === 'getAgencyYearlyReportSales') {
                                    var agentName = $('#breadcrumb li:nth-child(' + breadcrumbLILength + ') a').attr('agentname');
                                    fileName = 'Agency Report for ' + agentName + '.csv';
                                } else {
                                    fileName = !agentName ? 'Agency Report for ' + agentName + nonEmptySearchValues.join('_') + '.csv' : 'Agency Report' + nonEmptySearchValues.join('_') + '.csv'
                                }
                            }
                        } else {
                            fileName = csvFileName + nonEmptySearchValues.join('_') + '.csv';
                        }

                        // works on IE and Edge to save file, would also work on Chrome, Firedox for desktop
                        FileSaver.saveAs(blob, fileName);
                        // window.open(window.origin + `/download/${result.serverFilename}/${csvFileName}.csv`);
                    }
                    that.commonService.hideLoader();
                    document.body.removeChild(msgDiv);
                };
                reader.readAsDataURL(blob);
            }
        })
    }


    JSON2CSV = (objArray, getParams, api) => {
        var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        let activeTabId = this.localStorageService.get('activeTabId');
        let activeSubTab = this.localStorageService.get('activeSubTab');
        let selectedSubTab = activeSubTab ? activeSubTab['id'] : '';
        var str = '';
        var line = '';
        let q1Title = '';
        let q2Title = '';
        q1Title = this.commonService.getQ1Title();
        q2Title = this.commonService.getQ2Title();
        var baseTableColumns = ['Premium', 'YTD', 'YTD Total', 'YTD Premium',
            'Jan Premium', 'Feb Premium', 'Mar Premium', 'Apr Premium', 'May Premium', 'Jun Premium', 'Jul Premium', 'Aug Premium', 'Sep Premium', 'Oct Premium', 'Nov Premium', 'Dec Premium',
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Payment Amount','Calc. Annualized Premium'];
        if ($('#quote').is(':checked')) {
            for (var index in array[0]) {
                if (this.companyFilter === 'yes' && this.userRole === 'Agent') {
                    if (index != 'AWN Hidden' && index != 'Agency' && index != 'Marketing Company' && (index != 'HierarchyId' && index != 'HierarchyName' && index != 'LevelName')) {
                        var value = index + '';
                        line += '"' + value.replace(/"/g, '""') + '",';
                    }
                } else {
                    if (this.activeTabId === 5 && $('#pageSubTabs .active').attr('id') === 'subtab5') {
                        if (index != 'AWN Hidden' && index != 'Marketing Company' && index != 'LevelName') {
                            var value = index + '';
                            line += '"' + value.replace(/"/g, '""') + '",';
                        }
                    } else {
                        if (index != 'AWN Hidden' && (index != 'HierarchyId' && index != 'HierarchyName' && index != 'LevelName')) {
                            var value = index + '';
                            line += '"' + value.replace(/"/g, '""') + '",';
                        }
                    }
                }
            }
        } else {
            for (var index in array[0]) {
                if (this.companyFilter === 'yes' && this.userRole === 'Agent') {
                    if (index != 'AWN Hidden' && index != 'Agency' && index != 'Marketing Company' && index !== 'Q1' && index !== 'Q2' && (index != 'HierarchyId' && index != 'HierarchyName' && index != 'LevelName')) {
                        line += index + ',';
                    } else if (index === 'Q1') {
                        line += '"' + q1Title + '",';
                    } else if (index === 'Q2') {
                        line += '"' + q2Title + '",';
                    }
                } else {
                    if (this.activeTabId === 5 && selectedSubTab === 13) {
                        if (index != 'AWN Hidden' && index != 'Marketing Company' && index != 'LevelName' && index !== 'Q1' && index !== 'Q2') {
                            line += index + ',';
                        } else if (index === 'Q1') {
                            line += '"' + q1Title + '",';
                        } else if (index === 'Q2') {
                            line += '"' + q2Title + '",';
                        }
                    } else {
                        if (index != 'AWN Hidden' && index !== 'Q1' && index !== 'Q2' && (index != 'HierarchyId' && index != 'HierarchyName' && index != 'LevelName')) {
                            line += index + ',';
                        } else if (index === 'Q1') {
                            line += '"' + q1Title + '",';
                        } else if (index === 'Q2') {
                            line += '"' + q2Title + '",';
                        }
                    }
                }
            }
        }

        line = line.slice(0, -1);
        str += line + '\r\n';

        for (var i = 0; i < array.length; i++) {
            var line = '';
            if ($('#quote').is(':checked')) {
                for (var index in array[i]) {
                    if (this.companyFilter === 'yes' && this.userRole === 'Agent') {
                        if (index != 'AWN Hidden' && index != 'Agency' && index != 'Marketing Company') {
                            var value = array[i][index] + '';
                            line += '"' + value.replace(/"/g, '""') + '",';
                        }
                    } else {
                        if (this.activeTabId === 5 && selectedSubTab === 13) {
                            if (index != 'AWN Hidden' && index != 'Marketing Company') {
                                var value = array[i][index] + '';
                                line += '"' + value.replace(/"/g, '""') + '",';
                            }
                        } else {
                            if (index != 'AWN Hidden') {
                                var value = array[i][index] + '';
                                line += '"' + value.replace(/"/g, '""') + '",';
                            }
                        }
                    }
                }
            } else {
                //formats the number to the $ currency
                const formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                });

                for (var index in array[i]) {
                    if (this.userRole !== 'Agent' && this.companyFilter === 'yes') {
                        if (this.activeTabId === 5 && selectedSubTab === 13) {
                            if (index != 'AWN Hidden' && index != 'Marketing Company') {
                                getExportCommon(index);
                            }
                        } else {
                            if (api === 'getAgencyYearlyReport' || api === 'getAgencyYearlyReportSales') {
                                if (index != 'AWN Hidden' && index != 'HierarchyId' && index != 'HierarchyName' && index != 'LevelName') {
                                    getExportCommon(index);
                                }
                            } else {
                                if (index != 'AWN Hidden') {
                                    getExportCommon(index);
                                }
                            }
                        }
                    } else {
                        if (api === 'getAgencyYearlyReport' || api === 'getAgencyYearlyReportSales') {
                            if (index != 'AWN Hidden' && index != 'Marketing Company' && index != 'HierarchyId' && index != 'HierarchyName' && index != 'LevelName') {
                                getExportCommon(index);
                            }
                        } else {
                            if (this.clientType === 'carrier') {
                                if (index != 'AWN Hidden' && index != 'Marketing Company') {
                                    getExportCommon(index);
                                }
                            } else {
                                if (index != 'AWN Hidden' && index != 'Agency' && index != 'Marketing Company') {
                                    getExportCommon(index);
                                }
                            }
                        }
                    }
                }

                function getExportCommon(idx: any) {
                    let index = idx;
                    if ($('#popupContainer').length !== 1 && (activeTabId === 2 || activeTabId === 3 || activeTabId === 4 || activeTabId === 6) && (index === 'Submitted Premium' || index === 'Annualized Premium' || index === 'Commission Basis' || index === 'Commission' || index === 'Override' || index === 'Earned Premium' || index === 'TAP' || index === 'Pending Premium' || index === 'Payment Amount' || index === 'Amount')) {
                        if (!array[i][index].toString().includes('$')) {
                            line += ('" $ ' + array[i][index].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')) + '",';
                        } else {
                            if (array[i][index] === null) {
                                var emptyStr = '';
                                line += ('"' + emptyStr) + '",';
                            } else {
                                line += ('"' + array[i][index]) + '",';
                            }
                        }
                    } else if ($('#popupContainer').length !== 1 && (activeTabId === 5)) {
                        if (array[i][index] && $('#toggleReport .toggleActive').attr('type') === '$' && !array[i][index].toString().includes('$') && (baseTableColumns.includes(index))) {
                            line += ('" $ ' + array[i][index].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')) + '",';
                        } else if (array[i][index] && !array[i][index].toString().includes('$') && (index === 'Jan Premium' || index === 'Feb Premium' || index === 'Mar Premium' || index === 'Apr Premium' || index === 'May Premium' || index === 'Jun Premium' || index === 'Jul Premium' || index === 'Aug Premium' || index === 'Sep Premium' || index === 'Oct Premium' || index === 'Nov Premium' || index === 'Dec Premium' || index === 'YTD Premium' || index === 'Q1' || index === 'Q2' || index === 'Change' || index === 'Trainee Premium' || index === 'Trainer Reward' || index === 'Referred Premium' || index === 'Referral Reward')) {
                            if ($('#toggleReport .toggleActive').attr('type') === '#' && (index === 'Q1' || index === 'Q2' || index === 'Change')) {
                                line += ('" ' + array[i][index].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')) + '",';
                            } else {
                                line += ('" $ ' + array[i][index].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')) + '",';
                            }
                        } else if (array[i][index] && !array[i][index].toString().includes('$') && (index === 'Percentage') || (array[i][index] === 0 && index === 'Percentage')) {
                            line += ('"' + new Intl.NumberFormat().format(array[i][index]) + ' %') + '",';
                        } else {
                            if (array[i][index] === 0 && $('#toggleReport .toggleActive').attr('type') === '$' && (index === 'Jan' || index === 'Feb' || index === 'Mar' || index === 'Apr' || index === 'May' || index === 'Jun' || index === 'Jul' || index === 'Aug' || index === 'Sep' || index === 'Oct' || index === 'Nov' || index === 'Dec' || index === 'YTD' || index === 'Q1' || index === 'Q2' || index === 'Change')) {
                                line += ('" $ ' + array[i][index].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')) + '",';
                            } else if ([27, 29].includes(selectedSubTab) && (index === 'Premium' || index === '1 Premium' || index === '2 Premium' || index === '3 Premium' || index === '4 Premium')) {
                                line += ('" $ ' + array[i][index].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')) + '",';
                            } else if (array[i][index] === 0 && (index === 'Jan Premium' || index === 'Feb Premium' || index === 'Mar Premium' || index === 'Apr Premium' || index === 'May Premium' || index === 'Jun Premium' || index === 'Jul Premium' || index === 'Aug Premium' || index === 'Sep Premium' || index === 'Oct Premium' || index === 'Nov Premium' || index === 'Dec Premium' || index === 'YTD Premium')) {
                                line += ('" $ ' + array[i][index].toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')) + '",';
                            } else {
                                if (array[i][index] === null) {
                                    var emptyStr = '';
                                    line += ('"' + emptyStr) + '",';
                                } else {
                                    if(index != 'AWN Hidden' && index != 'HierarchyId' && index != 'HierarchyName' && index != 'LevelName'){
                                        line += ('"' + array[i][index]) + '",';
                                    }
                                }
                            }
                        }
                    } else if ($('#popupContainer').length === 1 && ['Split %', 'Commission Rate', 'Commission Split', 'Target Premium', 'Submitted Premium', 'Total Premium', 'Commission Rate', 'Commission Basis', 'TAP', 'Commission', 'Annualized Premium', 'Payment Amount', 'Trainee/Agent Premium', 'Trainee/Agent Target', 'Trainee/Agent Reward', 'Trainer Reward', 'Agent Premium', 'Referral Reward','Calc. Annualized Premium'].indexOf(index) >= 0) {
                        if (index === 'Split %' || index === 'Commission Split' || index === 'Commission Rate') {
                            line += ('"' + new Intl.NumberFormat().format(array[i][index]) + ' %') + '",';
                        } else if (!array[i][index].toString().includes('$')) {
                            line += ('"' + formatter.format(array[i][index]).replace(/^(\D+)/, '$1 ')) + '",';
                        } else {
                            if (array[i][index] === null) {
                                var emptyStr = '';
                                line += ('"' + emptyStr) + '",';
                            } else {
                                line += ('"' + array[i][index]) + '",';
                            }
                        }
                    } else {
                        if (array[i][index] === null) {
                            var emptyStr = '';
                            line += ('"' + emptyStr) + '",';
                        } else {
                            line += ('"' + array[i][index]) + '",';
                        }
                    }
                }
            }

            line = line.slice(0, -1);
            str += line + '\r\n';
        }
        return str;
    };


    createDownloadMessage() {
        let msgDiv = document.createElement("div");
        msgDiv.innerHTML = `<div class="spinner" aria-hidden="true"></div> <span class="px-1"> Preparing Download...</span>`;
        msgDiv.classList.add("download-flash-message");
        return msgDiv;
    }

    getFile(serverFilename, fileName, msgDiv, retryCount = 0) {
        let is_iPad = navigator.userAgent.match(/iPad/i) !== null;
        let retryTimeout = 5000;
        this.commonService.getFileStatus(serverFilename).subscribe((res: any) => {
            console.log(res);
            if (!res || (typeof res == 'object' && Object.keys(res).length == 0)) {
                document.body.removeChild(msgDiv);
            } else if (res['isGenerated'] == true) {
                document.body.removeChild(msgDiv);
                if (is_iPad) {
                    // Chrome, Firefox, and Chrome and Safari on iOS
                    let dataUrl = window.origin + `/download/${serverFilename}/${fileName}`;
                    let open = window.open(dataUrl);
                } else {
                    FileSaver.saveAs(window.origin + `/download/${serverFilename}/${fileName}`, fileName);
                    // window.open(window.origin + `/download/${result.serverFilename}/${csvFileName}.csv`);
                }
            } else {
                setTimeout(() => this.getFile(serverFilename, fileName, msgDiv), retryTimeout);
            }
        }, (err: HttpErrorResponse) => {
            if (retryCount < 20) {
                setTimeout(() => {
                    this.getFile(serverFilename, fileName, msgDiv, retryCount);
                    retryCount++;
                }, retryTimeout);
            } else {
                document.body.removeChild(msgDiv);
            }
        })
    }
}
