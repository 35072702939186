<flash-messages></flash-messages>
<ng-template #myNewsContent let-c="close" let-d="dismiss">
    <div class="modal-header">
        <!-- <h4 class="modal-title" id="modal-basic-title">My Agency Feeds</h4> -->
        <span id="myNewsHostName" class="modal-title"
            style="color: #1ABB9A !important;font-size: 18px !important;font-weight: 700;">My Agency Feeds</span>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body news-container-modal-body">
        <div *ngFor="let news of myFeedsList" (click)="onSelectionNews(news)">
            <div class="news-container d-flex align-items-center">
                <div class="p-2 text-center" style="width: 15%;">
                    <img *ngIf="news.source?.source?.toLowerCase().includes('facebook')" src="assets/images/resources_facebook_round.png">
                    <img *ngIf="news.source?.source?.toLowerCase().includes('youtube')" src="assets/images/resources_youtube_round.png">
                    <img *ngIf="news.source?.source?.toLowerCase().includes('twitter')" src="assets/images/resources_twitter_round.png">
                    <img *ngIf="news.source?.source?.toLowerCase().includes('linkedin')" src="assets/images/resources_linkdin_round.png">
                    <img *ngIf="news.source?.source?.toLowerCase().includes('instagram')" src="assets/images/resources_instagram_round.png"
                        width="48">
                    <img *ngIf="news.source?.source?.toLowerCase().includes('rssfeed')" src="assets/images/resources_rssfeed_round.png">
                </div>
                <div class="news-right-container p-2 d-flex flex-column" style="width: 85%;">
                    <a class="news-title overflow-ellipsis cursor-pointer"> {{ getDescription(news.title) }} </a>
                    <span id="newsDescription" class="mt-2 text-justify"> {{ getDescription(news.description) }}</span><br>
                    <div class="news-creator-and-date w-100">
                        <span class="font-italic">by {{ news.dc_creator }}</span>
                        <span class="pull-right"> {{ news.pubDate | date: 'dd-MMM-yyyy' }} </span>
                    </div>
                </div>
            </div>
            <hr class="news-horizontal-line">
        </div>
    </div>
</ng-template>

<ng-template #cancelSubscription let-c="close" let-d="dismiss">
    <div class="modal-header">
        <!-- <h4 class="modal-title" id="modal-basic-title">My Agency Feeds</h4> -->
        <span id="host_name" class="modal-title"
            style="color: #1ABB9A !important;font-size: 18px !important;font-weight: 700;">     
        <span>Do you want to cancel the AgentVizion registration?</span></span>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body stripe-container-modal-body">
        <div class="cancel-subscription-feedback-msg" >
            <span><b>Sorry to see you go...</b></span>
            <span><b>Please tell us the reason for your cancellation.</b></span>
            <textarea id="feedbackReason" rows="4" cols="50"></textarea>
        </div>
        <div class='cancel-msg'>
            <span class="cancel-subscription-msg">
            Cancellation will take effect at the end of your billing cycle. Upon cancellation, no refund will be issued, but you will not be charged the next time.
            </span>
        </div>
        
        <div class="panel-body modal-body table-responsive text-align-center" id="cancelSubscriptionPopupContainer">
            <button id="blockBtnId" type="button" class="btn btn-primary margin-right-10 green-bgColor" (click)="cancelSubscriptionOk()">Yes</button>
            <button type="button" class="btn btn-secondary green-bgColor" style="color: #fff;" (click)="d('Cross click')">No</button>
        </div>
    </div>
</ng-template>

<!-- <ng-container *ngTemplateOutlet="subscriptionDetail; context:subscriptionDetail"></ng-container> -->
<ng-template #subscriptionData let-c="close" let-d="dismiss">
    <div class="modal-header">
        <!-- <h4 class="modal-title" id="modal-basic-title">My Agency Feeds</h4> -->
        <span id="myNewsHostName" class="modal-title"
            style="color: #1ABB9A !important;font-size: 18px !important;font-weight: 700;">     
        <span>My Registration Details</span></span>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body stripe-container-modal-body">
        <div class="col-12 d-flex justify-content-center subscription-detail">
            <div class='w-100' style="max-width: 500px;">
                <span class="width-140">Registration type </span><span>&nbsp; : &nbsp;{{subscriptionDetail.subscriptionType}}</span><br>
                <!-- <span class="width-140">Plan </span><span>&nbsp : &nbsp{{subscriptionDetail.plan}}</span><br> -->
                <span class="width-140">Registration on </span><span>&nbsp; : &nbsp;{{subscriptionDetail.subscriptionDate  | date}}</span><br>
                <span class="width-140">Current cycle </span><span>&nbsp; : &nbsp;{{subscriptionDetail.currentCycle[0]|date}} to {{subscriptionDetail.currentCycle[1]|date}}</span><br>
                <span class="width-140">Next invoice </span><span>&nbsp; : &nbsp;{{subscriptionDetail.nextInvoice | date}}</span><br>
            </div>
            </div>
    </div>
</ng-template>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<div *ngIf="isHeaderLoaded" class="header-main-container">
    <div class="d-flex header m-0 header-style">
        <div class="logodiv p-2 p-0 d-flex"><img class="logo m-auto background-white" src="assets/{{logo}}"></div>
        <div class="px-0 header-center-container">
            <nav class="navbar navbar-expand-lg navbar-light h-100 pb-0" id="pageTabs">
                <!-- <a class="navbar-brand" href="#">Navbar</a> -->

                <button class="navbar-toggler background-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                    aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse w-100" id="navbarNav">
                    <ul class="navbar-nav w-100 h-100">
                        <li *ngFor="let tab of tabsData" class="nav-item px-1 cursor-pointer"
                            id={{tab.tab_id}} [ngClass]="tab.tab_id == selectedTab ? 'active': '' ">
                            <!-- <span *ngIf=" tab.id !== 'tab5' && tab.id !== 'tab6'" [ngClass]="{'disabled' : tab.defaultTab}"  class="fa fa-play-circle helpLink activeLink" (click)="openHelpLink({data:tab})"></span> [ngClass]="(tab.id === 'tab5' || tab.id ==='tab6') ? 'margin-top-20':''" -->
                            <a id="{{tab.tab_id}}" class="nav-link pb-3 h-100"
                                (click)="getTabContent({id:tab.tab_id, tabObj: tab})">{{tab.tab_name}}</a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
        <div *ngIf="avLiteUrl" class="d-flex align-items-center justify-content-center lite-advanced-switch">
            <ui-switch checkedLabel="LITE" (valueChange)="goToAVLite($event)"
            uncheckedLabel="PRO" 
            size="small"></ui-switch>
        </div>
        <div class="dropdown avatar header-right-container d-flex justify-content-end align-items-center px-0">
            <div class="d-flex align-items-center text-white font-weight-bolder header-right-avatar px-3">
                <div class="avatar_icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                        class="bi bi-person " viewBox="0 0 16 16">
                        <path
                            d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z" />
                    </svg>
                </div>

                <div class="d-flex flex-column px-2">
                    <button class="btn dropdown-toggle avatarbtn px-2 p-0" type="button" id="dropdownMenuButton"
                        data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{userInfo?userInfo['name']:''}}
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-chevron-down" viewBox="0 0 16 16">
                            <path fill-rule="evenodd"
                                d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                        </svg>
                    </button>
                    <div class="labeladmi px-2">{{loginUserRole}}</div>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-caret-up-fill arrow " viewBox="0 0 16 16">
                            <path
                                d="m7.247 4.86-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z" />
                        </svg>
                        <img class="logo" src="assets/images/user-icon.svg">
                        <!-- <img class="logo" src="assets/{{logo}}"> -->
                        <div class="name text-color2 mt-2">{{userInfo?userInfo['name']:''}}</div>
                        <div class="name_d mb-2"><small><small>{{loginUserRole}}</small></small></div>
                        <div class="line"></div>
                        <!-- <ul>
                <li>Registration</li>
                <li>Payment Info.</li>
            </ul> -->
                        <div class="line"></div>
                        <ul class="text-start mt-2">
                            <li class="cursor-pointer">
                                <a id="myFeedsDetails" (click)="onMyNews(myNewsContent)">My Feeds</a>
                            </li>
                            <li class="cursor-pointer">
                                <a (click)="onChangePassword()">Change Password</a>
                            </li>
                           <li *ngIf="userRole!='Manager'" class="cursor-pointer">
                            <a (click)="cancelSubscriptions(cancelSubscription)" id="cancelSubscription">Cancel my registration</a>
                           </li>
                           <li *ngIf="userRole!='Manager'" class="cursor-pointer">
                            <a (click)="updateCard()" id="updateCard">Update my card</a>                             
                           </li>
                           <li *ngIf="userRole!='Manager'" class="cursor-pointer">
                            <a (click)="subscriptionDetails(subscriptionData)" id="subscriptionDetails">My registration details</a>                           
                           </li>
                           <!-- <li *ngIf="avLiteUrl" class="cursor-pointer">
                            <a (click)="goToAVLite()" id="avLiteBtn">Go to AV Lite</a>                           
                           </li> -->
                        </ul>
                        <button type="button" class="btn btn-primary mt-4 background-primary3 py-1" (click)="logout()">SIGN OUT</button>
                        <!--         <a class="dropdown-item" href="#">Action</a>
            <a class="dropdown-item" href="#">Another action</a>
            <a class="dropdown-item" href="#">Something else here</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 header-container d-none">
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 margin-top-10 margin-bottom-10">
            <!-- <img id="logoImage" class="user-avatar logo"> -->
            <img src="assets/{{logo}}" class="header-logo-img">
        </div>
        <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 tabs-container">
            <ul class="nav nav-tabs top-tab" id="pageTabs">
                <!-- [ngClass]="(tab.defaultTab)?'active':''" -->
                <li *ngFor="let tab of tabsData" id={{tab.id}}>
                    <!-- <span *ngIf=" tab.id !== 'tab5' && tab.id !== 'tab6'" [ngClass]="{'disabled' : tab.defaultTab}"  class="fa fa-play-circle helpLink activeLink" (click)="openHelpLink({data:tab})"></span> [ngClass]="(tab.id === 'tab5' || tab.id ==='tab6') ? 'margin-top-20':''" -->
                    <a id="{{tab.id}}" [ngClass]="tab.id === selectedTab ? 'active' : 'none'" class="nav-link"
                        (click)="getTabContent({id:tab.id})">{{tab.label}}</a>
                </li>
                <!-- *ngIf="tab.defaultTab" [ngClass]="{'disabled' : tab.defaultTab}"  -->
            </ul>
        </div>
        <div class="col-xs-2 col-sm-2 col-md-2 col-lg-2 padding-top-25">
            <div>
                <div class="vertical-grey-line"></div>
                <div class="profile-container">
                    <div>
                        <i class="fa fa-user fa-lg user-logo"></i>
                    </div>
                    <div class="user-container">
                        <span id="loggedUserName" class="user-name">{{userInfo?userInfo['name']:''}}</span><br>
                        <span id="loggedUserRole" class="user-name-role ">{{userRole}}</span>
                    </div>
                </div>
                <div>
                    <div class="dropdown-toggle profile-open" data-bs-toggle="dropdown">
                        <ul class="dropdown-menu margin-top-20">
                            <div class="user-profile">
                                <i class="fa fa-user-circle-o fa-3x" aria-hidden="true"></i><br>
                                <span id="UserName">{{userInfo?userInfo['name']:''}}</span><br>
                                <span id="UserRole">{{userRole}}</span>
                            </div>
                            <div class="margin-top-15 text-align-center">
                                <span class="hr-line"></span>
                            </div>
                            <div>
                                <!-- <li class="text-align-center">
                                    <a id="subscriptionDetails" (click)="onMyNews(myNewsContent)">My Feeds</a>
                                </li> -->
                            </div>
                            <!-- <li class="text-align-center">
                                <a id="subscriptionDetails" (click)="onChangePassword()">Change Password</a>
                            </li>
                            <div class="text-align-center cursor-pointer;">
                                <a (click)="logout()" ><i class="icon-off" ></i> Logout</a>
                            </div> -->
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<button hidden="true" data-bs-toggle="modal" id="openCardModal" data-bs-target="#cardDetailsModal">HiddenModal</button>

<button hidden="true" data-bs-toggle="modal" id="registrationModal" data-bs-target="#regDetailsModal">HiddenModal</button>

<app-cards *ngIf="showCardsPopup" [registrationDetails]="registrationDetails"></app-cards>