<div class="modal-header">
	<h4 class="modal-title">{{title}}</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    </button>
</div>
<div class="modal-body mainpanal" style="margin:0 !important">
  <div *ngIf="!currentPointsValue || currentPointsValue=='$null'">{{noDataMessage}}</div>
	<div *ngIf="currentPointsValue && currentPointsValue!='$null'" class="panel-body modal-body table-responsive" id="popupContainer" style="padding: 10px;">
        <div class="bulletChartContainer ng-scope">
    <!-- <div class="bulletChartLabel">
        <span class="agentPoints">{{this.currentPointsValue}} Points</span>
    </div> -->
    <div class="barsContainer">
        <!-- New design for points start-->
        <h4 class="program-title mb-3">{{currentProgramName}}</h4>
        <div *ngIf="progressType == 'premium';" class="d-flex">
          <div class="current-premium-value">Current Premium: <span>{{ this._helperService.convertNumberToCommaSeparated(this.currentPointsValue, false)}}</span></div>
          <div class="current-premium-value ms-5">Target Premium: <span>{{this._helperService.convertNumberToCommaSeparated(rangeArray[3], false)}}</span></div>
        </div>
        <div *ngIf="progressType == 'points';" class="d-flex">
          <div class="current-premium-value">Current Points: <span>{{ this.currentPointsValue }}</span></div>
          <div class="current-premium-value ms-5">Target Points: <span>{{ this.targetPoints }}</span></div>
        </div>
        <div class="incentive-progressbar-container">
          <div [ngStyle]="{'left': 'calc('+ convertIncentiveToPercentage(true) + ' - 40px)'}" class="incentive-points-container">
            <div class="incentive-points-outer-circle">
              <div class="incentive-points-inner-circle">
                <div *ngIf="progressType == 'points';" class="incentive-point">{{this.currentPointsValue}}</div>
                <div *ngIf="progressType == 'premium';" class="incentive-point">{{convertIncentiveToPercentage()}}</div>
              </div>
            </div>
            <div class="incentive-pointer"></div>
          </div>
          <div class="position-absolute incentive-progressbar-dummy-left"></div>
          <div class="position-absolute incentive-progressbar-dummy-right"></div>
          <div class="incentive-progressbar" style="--separator1:calc({{getRangePercentage(rangeArray[1])}} - 0px); --separator2:calc({{getRangePercentage(rangeArray[2])}} - 0px)"></div>
          <div style="--left-range: calc({{getRangePercentage(rangeArray[0])}} - 15px)" class="range-container">
            <div class="incentive-range-pointer"></div>
            <div *ngIf="progressType == 'points';" class="incentive-range-text">{{this.rangeArray?.length && this.rangeArray[0]}}</div>
            <div *ngIf="progressType == 'premium';" class="incentive-range-text">{{this._helperService.convertNumberToCommaSeparated(rangeArray[0], false)}}</div>
          </div>
          <div style="--left-range: calc({{getRangePercentage(rangeArray[1])}} - 15px)" class="range-container">
            <div class="incentive-range-pointer"></div>
            <div *ngIf="progressType == 'points';" class="incentive-range-text">{{this.rangeArray?.length && this.rangeArray[1]}}</div>
            <div *ngIf="progressType == 'premium';" class="incentive-range-text">{{this._helperService.convertNumberToCommaSeparated(rangeArray[1], false)}}</div>
          </div>
          <div style="--left-range: calc({{getRangePercentage(rangeArray[2])}} - 15px)" class="range-container">
            <div class="incentive-range-pointer"></div>
            <div *ngIf="progressType == 'points';" class="incentive-range-text">{{this.rangeArray?.length &&  this.rangeArray[2]}}</div>
            <div *ngIf="progressType == 'premium';" class="incentive-range-text">{{this._helperService.convertNumberToCommaSeparated(rangeArray[2], false)}}</div>
          </div>
          <div style="--right-range: calc(0% - 15px)" class="range-container">
            <div class="incentive-range-pointer"></div>
            <div *ngIf="progressType == 'points';" class="incentive-range-text">{{this.rangeArray?.length &&  this.rangeArray[3]}}</div>
            <div *ngIf="progressType == 'premium';" class="incentive-range-text">{{this._helperService.convertNumberToCommaSeparated(rangeArray[3], false)}}</div>
          </div>
        </div>
        <!-- New design for points end-->

        <!-- <div class="currentPoints" [ngStyle]="{'width':myCurrentPointsStyles()}"></div>
        <div *ngFor="let range of ranges; let i = index">
         <div class="chartLevel{{i}}" [ngStyle]="{'width':myStyles(range)}">
            <div class="bulletChartxaxis">{{range}}</div>
        </div> -->
        <!-- <div class="chartLevel0" style="width:83.33333333333333%">
            <div class="bulletChartxaxis">150</div>
        </div>
        <div class="chartLevel1" style="width:111.11111111111111%">
            <div class="bulletChartxaxis">200</div>
        </div>
        <div class="chartLevel2" style="width:100%">
            <div class="bulletChartxaxis">180</div>
        </div> -->
    </div>
    <div *ngIf="progressType == 'premium';" class="bulletChartLegends">
      <div class="chartLegend">
          <span class="point-scale">75%</span>
          <div class="lagendColor0 d-inline-block"></div>
          <span class="lagendText">{{this._helperService.convertNumberToCommaSeparated(rangeArray[1], false)}}</span>
      </div>
      <div class="chartLegend">
          <span class="point-scale">90%</span>
          <div class="lagendColor1 d-inline-block"></div>
          <span class="lagendText">{{this._helperService.convertNumberToCommaSeparated(rangeArray[2], false)}}</span>
      </div>
      <div class="chartLegend">
          <span class="point-scale">100%</span>
          <div class="lagendColor2 d-inline-block"></div>
          <span class="lagendText">{{this._helperService.convertNumberToCommaSeparated(rangeArray[3], false)}}</span>
      </div>
  </div>
    <div *ngIf="progressType == 'points';" class="bulletChartLegends">
        <div class="chartLegend">
            <span class="point-scale">{{rangeArray[0]}}-{{rangeArray[1]}}</span>
            <div class="lagendColor0 d-inline-block"></div>
            <span class="lagendText">{{legendMessages[0]}}</span>
        </div>
        <div class="chartLegend">
            <span class="point-scale">{{rangeArray[1]}}-{{rangeArray[2]}}</span>
            <div class="lagendColor1 d-inline-block"></div>
            <span class="lagendText">{{legendMessages[1]}}</span>
        </div>
        <div class="chartLegend">
            <span class="point-scale">{{rangeArray[2]}}-{{rangeArray[3]}}</span>
            <div class="lagendColor2 d-inline-block"></div>
            <span class="lagendText">{{legendMessages[2]}}</span>
        </div>
    </div>
</div>
	</div>
</div>
<div class="modal-footer">
	<!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
</div>
