<div class="d-flex justify-content-end">
    <div id="agentslastupdatedContainer" [ngStyle]="{display:activeRoute.includes('dashboard')?'none':''}" class="text-end px-1 py-1">
        <small id="agentsLastupdated" style="font-size: 12px;">
            <p style="margin-bottom: 0px;">
            </p>
        </small>
    </div>
    <div id="lastUpdatedDate"
        *ngIf="oneCarriersSelected && clientType !== 'carrier' && activeTabId !== 2 && activeTabId !== 7"
        class="text-end d-flex justify-content-end px-1 py-1">
            <small id="selectedCarrierCount1" style="font-size: 12px; display: flex;">
                <p *ngFor="let option of lastUpdatedDate" style="margin-right: 15px;margin-bottom: 0px;">
                    {{carriersSelected.length==1? carriersSelected:''}} {{option.key}} data as of : {{ option.date }}
                </p>
            </small>
    </div>
    <div id="lastUpdatedDate"
        *ngIf="clientType === 'carrier' && activeTabId != 2"
        class=" text-end d-flex justify-content-end px-1 py-1">
        <small id="selectedCarrierCount1" style="font-size: 12px; display: flex;">
            <p *ngFor="let option of lastUpdatedDate" style="margin-right: 15px;margin-bottom: 0px;">
                {{option.key}} data as of : {{option.date}}
                </p>
        </small>
    </div>
</div>
<div class="row selectors filder-holder selectors-background " [ngStyle]="{display:activeRoute.includes('dashboard')?'none':''}">
    <div class="col-12">
        <div class="row mx-0 mt-2">
        <div *ngFor="let filterGroup of filtersObj; let i=index;" class="selectorBlock px-1 selectorGroup" [ngClass]="i==3?'col-6':'col-2'">
            <div class="row mx-0 h-100 align-content-between">
              <!-- removing id="{{selector.id}}_container" to avoid unnecessary hiding -->
                <div *ngFor="let selector of filterGroup let j=index;" class="px-0" style="min-height: 52px;" [ngClass]="calculateDisplay(i, selector.id)" [ngStyle]="{'z-index':1000-j}">
                    <!-- {{selector | json}} -->
                        <!-- {{selector.name}} -->
                    <div *ngIf="selector.type === 'select'">
                        <div class="position-relative">
                            <div [ngClass]="show_lob?.className" *ngIf="selector.name === 'Line of Business'" [ngClass]="show_lob?.show?'show-on-display':'hide-on-display'">
                                <ul class="list-unstyled selectFilter" id="{{selector.id}}">
                                    <li class="init" (click)="dropDownEvent($event)">Select Line Of Business</li>
                                    <li class="option" value="All" (click)="updatePageContent($event)">All</li>
                                    <li class="option" *ngFor="let option of selector['values']" (click)="updatePageContent($event)">{{option.category}}</li>
                                </ul>
                                <small class="invisible">Selected  : <span><b>label</b></span></small>
                            </div>
                            <div [ngClass]="show_product_type?.className" class="d-flex flex-column" *ngIf="selector.name === 'Product'" style="min-height: 44px;" [ngClass]="show_product_type?.show?'show-on-display':'hide-on-display'">
                                <ul class="list-unstyled selectFilter cursor-pointer" id="{{selector.id}}">
                                    <li class="init" (click)="dropDownEvent($event)">Select Product Type</li>
                                    <li class="option" value="All" (click)="updatePageContent($event)">All</li>
                                    <li class="option" *ngFor="let option of selector['values']" (click)="updatePageContent($event)" value="option.category">{{option.category}}</li>
                                </ul>
                                <small class="invisible">Selected  : <span><b>label</b></span></small>
                                <div *ngIf="medicareAdvantageSaleType.toLowerCase() === 'yes'" id="medicareAdvantageFilterContainer" class="mt-4">
                                    <div class="row mx-0">
                                        <div *ngFor="let saleType of saleTypeList;" class="col-6 radio px-1">
                                            <label class="d-inline-flex">
                                                <input type="radio"
                                                    value="1"
                                                    [checked]="medicareAdvantageFilter['filterValue'] === saleType['sale_type']"
                                                    (change)="onMedicareAdvantageChange(saleType['sale_type'])"
                                                    name="ProfitCode"><small id="saleTypeAll" style="padding-left: 5px;">{{saleType["sale_type_display_name"]}}</small>
                                            </label>
                                        </div>
                                        <!-- <div class="radio px-1">
                                            <label class="d-inline-flex">
                                                <input type="radio"
                                                    value="1"
                                                    [checked]="medicareAdvantageFilter['filterValue'] === 'All'"
                                                    (change)="onMedicareAdvantageChange('All')"
                                                    name="ProfitCode"><small id="saleTypeAll" style="padding-left: 5px;">All</small>
                                            </label>
                                        </div>
                                        <div class="radio px-1">
                                            <label class="d-inline-flex">
                                                <input type="radio"
                                                    value="2"
                                                    [checked]="medicareAdvantageFilter['filterValue'] === 'New Sales'"
                                                    (change)="onMedicareAdvantageChange('New Sales')"
                                                    name="ProfitCode"><small id="saleTypeNewSales" style="padding-left: 5px;">New Sales</small>
                                            </label>
                                        </div>
                                        <div class="radio px-1">
                                            <label class="d-inline-flex">
                                                <input type="radio"
                                                    value="3"
                                                    [checked]="medicareAdvantageFilter['filterValue'] === 'Others'"
                                                    (change)="onMedicareAdvantageChange('Others')"
                                                    name="ProfitCode"><small id="saleTypeOthers" style="padding-left: 5px;">Others</small>
                                            </label>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div [ngClass]="show_filter_by?.className" *ngIf="selector.name === 'Filter By'" [ngClass]="show_filter_by?.show?'show-on-display':'hide-on-display'">
                                <ul class="list-unstyled selectFilter cursor-pointer" id="{{selector.id}}">
                                    <li class="init" (click)="dropDownEvent($event)">{{filterDateTypes}}</li>
                                    <li class="option" *ngFor="let option of selector['values']" (click)="updatePageContent($event)" value="option.category">{{option.category}}</li>
                                </ul>
                                <small class="invisible">Selected  : <span><b>label</b></span></small>
                            </div>
                        </div>
                    </div>
                    <!-- <div id="jstree_data" #dataTree></div> -->
                    <div [ngClass]="filterClass(selector.id)" *ngIf="selector.type === 'multiSelect'" class="row mx-0">
                        <div class="col-12 px-0 d-flex flex-column dropdown-toggle-main position-relative">
                            <input (click)="selectorClick($event, selector.id)"
                            class="{{ selector.id === 'agency-select'  && activeTabId === 6 ? 'disabled disable-events' : ''}} multi-select-container dropdown-toggle btn btn-default dropdown-toggle-list text-start overflow-ellipsis"
                            value={{selector.placeHolder}} id="dropdownMenu{{selector.id}}" type="button"
                            aria-haspopup="true" aria-expanded="true">
                            <div>
                                <i *ngIf="selector.id === 'company' && companyFilter === 'yes' && userRole === 'Manager'" class="fa fa-wifi-1 dot"></i><small *ngIf="selector.id === 'company'" class="carrierCount">Selected {{selector.name}}: <span id="selectedCompanyCount" style="font-weight: 700;"><b>{{companyList.length}}</b></span></small>
                                <i *ngIf="selector.id === 'carriers'" class="fa fa-wifi-1 dot"></i><small *ngIf="selector.id === 'carriers'" class="carrierCount">Selected {{selector.name}}: <span id="selectedCarrierCount" style="font-weight: 700;"><b>{{carrierList.length}}</b></span></small>
                                <i *ngIf="selector.id === 'agency-select'" class="fa fa-wifi-1 dot"></i><small *ngIf="selector.id === 'agency-select'" class="carrierCount">Selected {{selector.name}}: <span id="selectedAgencyCount" style="font-weight: 700;"><b>{{agencyList.length}}</b></span></small>
                                <i *ngIf="selector.id === 'policyStatusFilter'" class="fa fa-wifi-1 dot"></i><small *ngIf="selector.id === 'policyStatusFilter'" class="carrierCount">Selected {{selector.name}}: <span id="selectedPolicyStatusCount" style="font-weight: 700;"><b>{{policyArrStatusLength}}</b></span></small>
                                <i *ngIf="selector.id === 'applicationStatusFilter' && isApplicationsApplicable === 'yes'" class="fa fa-wifi-1 dot"></i><small *ngIf="selector.id === 'applicationStatusFilter'" class="carrierCount">Selected {{selector.name}}: <span id="selectedapplicationStatusCount" style="font-weight: 700;"><b>{{applicationArrStatusLength}}</b></span></small>
                            </div>
                            <ul id="multiselectdropdown" class="dropdown-menu" style="margin-top: 32px;">
                                <div id="{{selector.id}}" class="multiSelectDropDown"></div>
                            </ul>
                        </div>
                    </div>
                    <div [ngClass]="show_from_date?.className" *ngIf="selector.tabId !== 'tab4' && selector.id === 'fromDate'" [ngClass]="show_from_date?.show?'show-on-display':'hide-on-display'">
                        <div class="row mx-0">
                            <div class="col-xs-12 col-sm-12 col-lg-12 px-1 pr-2 position-relative" >
                                <div class="d-flex position-relative">
                                    <div class="date-label-style control-label" for="date">Fr:</div>
                                    <input type="text"
                                    class="form-control date cursor-pointer selector-date-input overflow-ellipsis"
                                    #dp="bsDatepicker"
                                    bsDatepicker
                                    (bsValueChange)="fromDateChanged($event)"
                                    [(bsValue)]="fromDate"
                                    [bsConfig]="{showWeekNumbers:false}">
                                    <span class="input-group-addon" (click)="dp.toggle()">
                                        <span class="fa fa-calendar pointer"></span>
                                    </span>
                                </div>
                                <!-- value="{{ fromDate | date:'dd-MMM-yy' }}" -->
                                <small class="invisible">Selected  : <span><b>label</b></span></small>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="show_to_date?.className" *ngIf="selector.tabId !== 'tab4' && selector.id === 'toDate'" [ngClass]="show_to_date?.show?'show-on-display ':'hide-on-display '">
                        <div class="row mx-0">
                            <div class="col-xs-12 col-sm-12 col-lg-12 pl-1 pr-0 position-relative">
                                <div class="d-flex position-relative">
                                    <div class="date-label-style control-label" for="date">To:</div>
                                    <input type="text"
                                    class="form-control height-28 date cursor-pointer selector-date-input overflow-ellipsis"
                                    bsDatepicker
                                    #dp="bsDatepicker"
                                    placeholder="Datepicker"
                                    (bsValueChange)="toDateChanged($event)"
                                    [(bsValue)]="toDate"
                                    [bsConfig]="{showWeekNumbers:false}">
                                    <span class="input-group-addon" (click)="dp.toggle()">
                                        <span class="fa fa-calendar pointer"></span>
                                    </span>
                                    <!--<input
                                    type="text" , dateInputFormat: 'yyyy-MM-dd'
                                    class="form-control" value="{{ toDate | date:'yyyy-MM-dd' }}"
                                    placeholder="Datepicker"
                                    bsDatepicker
                                    value="Fr:{{ myDate | date: 'dd-MMM-yy' }}"
                                    [(ngModel)]="toDate"

                                    /> -->
                                </div>
                                <small class="invisible">Selected  : <span><b>label</b></span></small>
                            </div>
                        </div>
                    </div>
                    <div [ngClass]="show_for_year?.className" *ngIf="selector.id === 'forYear'" [ngClass]="show_for_year?.show?'show-on-display ':'hide-on-display '">
                        <div class="row mx-0" >
                            <div class="col-xs-12 col-sm-12 col-lg-12 px-1 position-relative">
                                <div class="d-flex position-relative">
                                    <div class="date-label-style control-label" for="date">For:</div>
                                    <input style="width: 0px;height: 0px;border: 0px;padding: 0;"
                                    bsDatepicker [(bsValue)]="forYear" #dp="bsDatepicker"
                                    [bsConfig]="{minMode:'year', adaptivePosition: true, showWeekNumbers:false, dateInputFormat: 'YYYY' }"
                                    (bsValueChange)="forYearDateChanged($event)"/>
                                    <input type="text"
                                    style="pointer-events: none;border-left: none;border-radius: inherit; padding-left: 0px;"
                                    class="form-control cursor-pointer selector-date-input overflow-ellipsis"
                                    [value]="forYear | date:'yyyy':'UTC+forYearOffset'"
                                    placeholder="">
                                    <span class="input-group-addon" (click)="dp.toggle()">
                                        <span class="fa fa-calendar pointer"></span>
                                    </span>
                                    <!-- <input [bsConfig]="{startView: 'year'}"
                                    value="{{forYear }}"  [(bsValue)]="forYear"
                                    bsDatepicker
                                    (bsValueChange)="forYearDateChanged($event)"
                                    class="form-control"
                                    placeholder="Datepicker"> -->
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>

    <div class="row mx-0 w-100 align-self-end " style="padding:0px;">
        <div class="col-2 selectors-button1 offset-8">
            <div *ngFor="let selector of toggleObj" id="{{selector.id}}_container" class="selectorBlock">
                <div *ngIf="selector.id === 'toggle'" id="{{selector.id}}">
                    <div class="toggleBtn">
                        <div class="tab">
                            <button *ngFor="let option of selector.values" [attr.id]="option.id" [attr.type]="option.value" [attr.api]="option.api"
                            (click)="onsaleAndPremiumToggle($event, option)" class="tablinks {{option.class}}">{{option.label}}</button>
                        </div>
                    </div>
                    <small class="invisible">Selected  : <span><b>label</b></span></small>
                </div>
                <div *ngIf="selector.id === 'toggleAgents'" id="{{selector.id}}" c>
                    <div class="toggleBtn">
                        <div class="tab">
                            <button *ngFor="let option of selector.values" [attr.api]="option.api" [attr.id]="option.id" [attr.type]="option.value"
                            (click)="onToggle($event, option)" class="tablinks {{option.class}}">{{option.label}}</button>
                        </div>
                    </div>
                    <small class="invisible">Selected  : <span><b>label</b></span></small>
                </div>
                <div *ngIf="selector.id === 'toggleReport'" id="{{selector.id}}">
                    <div class="toggleBtn">
                        <div class="tab">
                            <button *ngFor="let option of selector.values" [attr.id]="option.id" [attr.type]="option.value" [attr.api]="option.api"
                            (click)="onReportToggle($event, option)" class="tablinks {{option.class}}">{{option.label}}</button>
                        </div>
                    </div>
                    <small class="invisible">Selected  : <span><b>label</b></span></small>
                </div>
            </div>
        </div>
        <div *ngFor="let selector of resetObj" id="{{selector.id}}_container" class="col-2 selectors-button-reset selectorBlock">
            <div class="text-end" *ngIf="selector.type === 'button'">
                <div class="text-end">
                    <button type="button" id="{{selector.id}}" (click)="onResetButton()" class="btn btn-success px-4 py-1 reset navbar-btn export-btn {{selector.class}}">{{selector.name}}</button>
                </div>
                <small class="invisible">Selected  : <span><b>label</b></span></small>
            </div>
        </div>
    </div>
</div>
